import React, { Component } from 'react';
import { Image} from 'react-bootstrap';
import logo from '../../../assets/images/logo-main.png';
import loginImage from '../../../assets/images/auth-banner.png';
import '../../../assets/scss/login.scss';
import LoginForm from '../../common/LoginForm';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  render() {
    return (
      <div className="page__wrapper">
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth login">
              <div className="row w-100">
                <div className="col-lg-8 m-auto"> 
                  <div className="deliva-form login-validation boxShadow1">
                    <div className="row">
                      <div className="col-md-6 pr-0">  
                        <Image
                          src={loginImage}
                          className="img-fluid login-img h-100"
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex justCenter align-items-center py-3 h-100">  
                          <div className="login-wrap login-marg">
                            <div className="text-center">
                              <Image
                                src={logo}
                                alt=""
                                className="w100 r8 mb-4 pb-3"
                              />
                            </div>
                            <LoginForm {...this.props} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;

import Validator from "validator";
import isEmpty from "lodash/isEmpty";
import ValidationMessages from "../helpers/ValidationMessages";

function validateInput(data) {
  console.log(data,"DAT@123")
  let errors = {};

  if (Validator.isEmpty(data.name)) {
    errors.name = ValidationMessages.firstName.required;
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = ValidationMessages.email.required;
  } else if (!Validator.isEmail(data.email)) {
    errors.email = ValidationMessages.email.invalid;
  }
  if (Validator.isEmpty(data.mobile)) {
    errors.mobile = ValidationMessages.phoneNumber.required;
  } else if (!Validator.isMobilePhone(data.mobile)) {
    errors.mobile = ValidationMessages.phoneNumber.invalid;
  } else if (data.mobile.length < 10) { // Ensure mobile number has at least 10 digits
    errors.mobile = "Mobile number must be at least 10 digits.";
  }


  if (Validator.isEmpty(data?.country)) {
    console.log(data.city, "DATCITY"); 
    errors.country = ValidationMessages.country.required;
  } else if (data.country.length < 3) {
    errors.country = "Country must be at least 3 characters."; 
  }

  if (Validator.isEmpty(data.city)) {
    console.log(data.city, "DATCITY"); // Debugging output
    errors.city = ValidationMessages.city.required;
  } else if (data.city.length < 2) {
    errors.city = "City must be at least 2 characters.";
  }
  if (data.roleId == '') {
    errors.role = ValidationMessages.role.required;
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

export default validateInput;

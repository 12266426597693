import React, { Component } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import matchSorter from "match-sorter";
import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import { Row, Col } from "react-bootstrap";
import delivaApiService from "../../../common/services/apiService";
import { getLocalStorage } from "../../../common/helpers/Utils";
import Pagination from "../DeliveryAgent/perfectpage";
import moment from "moment";

class BidTables extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      searchInput: "",
      details: [],
      data: [],
      defaultPageSize: 5,
      resized: [{ id: "default", value: 5 }],
      pageSize: 5,
      inputValue :'5',
      apiname: "",
      isLoadingBidData:false
    };
    this.filterAll = this.filterAll.bind(this);
  }
  componentDidMount() {

    this.setState(
      {
        pageName: this.props.match.params.name,
        apiName:
          this.props.match.params.name == "totalbids"
            ? "getSubmittedBidsForDA"
            : "getOpenBidsForDA",
      },
      () => {
        this.getOrders(this.state.apiName);
      }
    );
  }
  textFilter(filter, row) {
    let result = parseInt(
      row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()),
      10
    );
    if (result < 0) {
      return false;
    } else {
      return true;
    }
  }

  onFilteredChange(filtered) {
    if (filtered.length > 1 && this.state.filterAll.length) {
      const filterAll = "";
      this.setState({
        filtered: filtered.filter((item) => item.id != "all"),
        filterAll,
      });
    } else this.setState({ filtered });
  }
  onRezise(page) {
  }
  filterAll(e) {
    const { value } = e.target;
    const filterAll = value;
    const filtered = [{ id: "all", value: filterAll }];
    this.setState({ filterAll, filtered });
  }

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

 
  globalSearch = () => {
    let { searchInput } = this.state;
  
    const searchQuery = searchInput.toLowerCase().replace(/\s+/g, ''); // Remove all spaces  
    let filteredData = this.state.details.filter((value) => {
      if (value?.bidDate) {        
        const formattedBidDate = moment(new Date(value?.bidDate))
          .format("DDMMMYYYY")
          .toLowerCase();
        if (formattedBidDate.includes(searchQuery)) {
          return true;
        }
      }
  
      if (value?.title) {
        return value.title.toLowerCase().includes(searchQuery);
      }
  
      return false;
    });
  
    this.setState({ data: filteredData });
  };
  

    getOrders(apiName) {
    delivaApiService(apiName, { id: getLocalStorage("DAid") })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.setState(
            {
              details: response.data.resourceData.data,
              totalCount: response.data.resourceData.count,
              isLoadingBidData:true

            },
            () => {
              this.setState(
                {
                  data: this.state.details.map((item) => {
                    return item;
                  }),
                }
              );
            }
          );
        } else {
          //   this.setLoader(false);
        }
      })
      .catch((err) => {
        // this.setLoader(false);
      });

    // }
  }
  // hello(pageSize) {
  //   this.setState({
  //     pageSize,
  //   });
  // }


  hello = (pageSize) => {
    if (pageSize === "") {
      // Allow input to be blank but do not update pageSize yet
      this.setState({ inputValue: "" });
    } else {
      let size = pageSize.replace(/^0+/, ""); // Remove leading zeros

      if (!size || isNaN(size) || size <= 0) {
        // Keep input blank but do not update pageSize
        this.setState({ inputValue: "" });
      } else {
        this.setState({
          pageSize: parseInt(size, 10),
          inputValue: size, 
        });
      }
    }
  };

  changepath = (path, props) => {
    this.props.history.push(path);
  };
  render() {
    const columns = [
      {
        Header: "Date",
        id: "bidDate",
        accessor: (d) => moment(new Date(d?.bidDate)).format("DD MMM YYYY"),
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "bidDate", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["requestId"] }),
        filterAll: true,
      },

      {
        Header: "Package Name",
        id: "title",
        accessor: (d) => d.title,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "title", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["title"] }),
        filterAll: true,
      },
      {
        Header: "Destination Location",
        id: "destinationLocation",
        accessor: (d) =>
          d?.destinationLocation
            ? (d?.destinationLocation || "") +
              ", " +
              (d?.destinationLocation || "")
            : d.destinationCity,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "destinationPaDetail.city", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ["destinationLocation"],
          }),
        filterAll: true,
      },
      {
        Header: "Pickup Location",
        id: "sourceLocation",
        accessor: (d) =>
          d?.sourceLocation
            ? (d?.sourceLocation|| "") +
              ", " +
              (d?.sourceLocation || "")
            : d?.sourceLocation,
        // accessor: (d) =>
        //   d?.sourcePaDetail?.businessName + ", " + d?.sourcePaDetail?.city,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "sourcePaDetail.city", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["sourceLocation"] }),
        filterAll: true,
      },
      {
        Header: "Bid Amount",
        id: "bidAmount",
        accessor: (d) => d.bidAmount,
        Cell: (props) => <span>₦{props.value}</span>, // Custom cell components!
        // accessor: "bidAmount", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["bidAmount"] }),
        filterAll: true,
      },
      {
        Header: "Status",
        id: "status",
        accessor: (d) => d.status,
        Cell: (props) => (
          <span
            className={`${
              props.value == "ACCEPTED_BID" ? "text-green" : "text-reddish"
            }`}
          >
            {props.value == "ACCEPTED_BID" ? "Accepted" : "Pending"}
          </span>
        ), // Custom cell components!
        // accessor: "status", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["status"] }),
        filterAll: true,
      },

      {
        Header: "All",
        id: "all",
        width: 0,
        show: false,
        resizable: false,
        sortable: false,
        Filter: () => {},
        getProps: () => {
          return {};
        },
        filterMethod: (filter, rows) => {
          const result = matchSorter(rows, filter.value, {
            keys: [
              "destinationLocation",
              "orderDate",
              "pickUpLocation",
              "status",
              "packageName",
            ],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          });
          return result;
        },
        filterAll: true,
      },
    ];
    const effectivePageSize = Math.min(this.state.pageSize, this.state.data?.length); // Prevent extra spaces // Prevent extra spaces

    const columns2 = [
      {
        Header: "Date",
        id: "bidDate",
        accessor: (d) =>  moment(new Date(d.bidDate)).format('DD MMM YYYY'),
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "title", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["bidDate"] }),
        filterAll: true,
      },     

      {
        Header: "Package Name",
        id: "title",
        accessor: (d) => d.title,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "title", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["title"] }),
        filterAll: true,
      },

      {
        Header: "Destination Location123",
        id: "destinationLocation",
        accessor: (d) => {
          console.log(d?.destinationPaDetail,"DETAINATION"); // Log to check the object
          if (d?.destinationPaDetail) {
            return (
              (d?.destinationPaDetail?.businessName || "") +
              ", " +
              (d?.destinationPaDetail?.city || "")
            );
          } else {
            return d.destinationCity || "Unknown";
          }
        },
        // ...rest of the code
      },
      
      {
        Header: "Pickup Location",
        id: "sourceLocation",
        accessor: (d) => 
        d?.sourcePaDetail ? ((d?.sourcePaDetail?.businessName || '') +
          ", " +
          (d?.sourcePaDetail?.city || '')):d.sourceCity,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "sourcePaDetail.city", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["sourceCity"] }),
        filterAll: true,
      },
      {
        Header: "Package Weight",
        id: "weight",
        accessor: (d) => d.weight,
        Cell: (props) => <span>{props.value ? props.value : "-"}</span>, // Custom cell components!
        // accessor: "weight", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["weight"] }),
        filterAll: true,
      },

      {
        Header: "All",
        id: "all",
        width: 0,
        show: false,
        resizable: false,
        sortable: false,
        Filter: () => {},
        getProps: () => {
          return {};
        },
        filterMethod: (filter, rows) => {

          const result = matchSorter(rows, filter.value, {
            keys: [
              "destinationLocation",
              "orderDate",
              "pickUpLocation",
              "status",
              "packageName",
            ],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          });
          return result;
        },
        filterAll: true,
      },
    ];
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 line-ht color-33 fs-24">
                        {this.props.match.params.name == "totalbids"
                          ? "Total Submitted Bids"
                          : "Open Bids"}
                        {" "}
                        <span className="color-grey">
                          ({this.state.totalCount})
                        </span>
                      </h3>
                      <h5 className="font-weight-normal mb-0 line-ht color-head">
                      <span
                          onClick={() =>
                            this.props.history.push(
                              '/deliveryagentlist'
                            )
                          }
                          className="pointer"
                        >
                        Delivery Agents
                        </span>
                        <span className="icon-next mx-2 Fs12"></span>
                        <span
                          onClick={() =>
                            this.props.history.push(
                              '/deliveryagentprofile'
                            )
                          }
                          className="pointer"
                        >
                        Delivery Agent’s profile{" "}
                        </span>
                        <span className="icon-next mx-2 Fs12"></span>
                        {this.props.match.params.name == "totalbids"
                          ? "Total Submitted Bids"
                          : "Open Bids"}
                      </h5>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      <input
                        name="searchInput"
                        value={this.state.searchInput || ""}
                        onChange={this.handleChange}
                        label="Search"
                        type="text"
                        placeholder="Search by Order Date and Package Name"
                        className="searchTxt w-100"
                      />
                      <span className="icon-search"></span>
                    </Col>
                  </Row>

                  
                  <div className="row">
    <div className="col-sm-12">
      <div className="table-wrap total-bid-table">
        <ReactTable className="totalSumbittedBidsTable"
          data={this.state.data}
          columns={
                            this.state.pageSize
                              ? this.state.pageName == "totalbids"
                                ? columns
                                : columns2
                              : []
          }
          //   pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          defaultPageSize={this.state.defaultPageSize}
          pageSize={effectivePageSize}
          showPageSizeOptions={true}
          filtered={this.state.filtered}
          //   resized={this.state.resized}
          ref={(r) => (this.reactTable = r)}
          onFilteredChange={this.onFilteredChange.bind(this)}
          onPageSizeChange={(pageSize, pageIndex) => {
          }}
          //   filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.age]) === filter.value
          }
          noDataText="No records found" 

          PaginationComponent={
            this.state.data.length > 0 ? Pagination : () => null
          }        />
            {this.state.data.length > 0 && (
        <span className="page-value">
          Enter Per Page{" "}
          <input
            type="text"
            placeholder="07"
            name="defaultPageSize"
            value={this.state.inputValue}
            onChange={(e) => this.hello(e.target.value)}
          />
        </span>)}
      </div>
    </div>
  </div>
  

               
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default BidTables;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux'
import { getAbbreviations } from '../../common/helpers/Utils'
import CONSTANTS from '../../common/helpers/Constants'
import { actionLogin } from '../../common/redux/actions/actionAuth'
import validateInput from '../../common/validations/validationLogin'
import Loader from '../../Component/common/Loader'
import { ReactComponent as ShowEyeicon } from '../../assets/images/eye-slash.svg'
import { ReactComponent as HideEyeicon } from '../../assets/images/eye-svgrepo.svg'

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      roleType: CONSTANTS.ROLES.ORGANIZER,
      username: '',
      password: '',
      errors: {},
      showLoader: false,
      showPassword: false,
    }
  }
  _isMounted = false
  componentDidMount() {
    this._isMounted = true
    const { url } = this.props.match
    if (url.indexOf('/organizer/') >= 0) {
      this.setState({
        roleType: CONSTANTS.ROLES.ORGANIZER,
      })
    } else if (url.indexOf('/vendor/') >= 0) {
      this.setState({
        roleType: CONSTANTS.ROLES.VENDOR,
      })
    } else {
      this.setState({
        roleType: CONSTANTS.ROLES.ADMIN,
      })
    }
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  redirectToForgotPassword = () => {
    if (this.state.roleType === CONSTANTS.ROLES.ORGANIZER) {
      this.props.history.push('/organizer/forgot')
    } else if (this.state.roleType === CONSTANTS.ROLES.VENDOR) {
      this.props.history.push('/vendor/forgot')
    } else {
      // this.props.history.push('/admin/forgotpassword');
      this.props.history.push({
        pathname: '/forgotpassword',
        state: {
          email: this.state.username,
        },
      })
    }
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state)
    if (!isValid) {
      this.setState({ errors })
    }
    return isValid
  }
  handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault()
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target
    if (name == 'username') {
      this.setState((prevState) => ({
        [name]: value.trim(),
        errors: {
          ...prevState.errors,
          email: '',
        },
      }))
    } else
      this.setState((prevState) => ({
        [name]: value.trim(),
        errors: {
          ...prevState.errors,
          [name]: '',
        },
      }))
  }
  handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text')
    if (/\s/.test(pastedData)) {
      event.preventDefault()
      const sanitizedData = pastedData.replace(/\s/g, '')
      document.execCommand('insertText', false, sanitizedData)
    }
  }

  handleSubmit = () => {
    this.setState(
      {
        showLoader: true,
      },
      () => {}
    )
    new Buffer('your string here').toString('base64')
    let data = {
      username: this.state.username.toLowerCase().trim(),
      password: this.state.password.trim(),
      roleId: this.state.roleType,
      loginBy: 'email',
      grant_type: 'password',
      deviceId: '123',
      timezone: getAbbreviations(),
    }

    if (this.isValid(data)) {
      data.password = new Buffer(this.state.password.trim()).toString('base64')
      this.props.actionLogin(data).then((result) => {
        if (result && result.status == 200) {
          // if (result.data.data.roleId === CONSTANTS.ROLES.ORGANIZER) {
          //   this.props.history.push("/organizer/dashboard");
          // } else if (result.data.data.roleId === CONSTANTS.ROLES.VENDOR) {
          //   this.props.history.push("/vendor/dashboard");
          // } else {
          this.props.history.push('/dashboard')
          // }
          if (this._isMounted) {
            this.setState({
              username: '',
              password: '',
              showLoader: false,
              errors: {},
            })
          }
        } else {
          this.setState({
            showLoader: false,
          })
        }
      })
    } else {
      this.setState({
        showLoader: false,
      })
    }
  }
  clear() {
    this.setState({
      username: '',
      password: '',
      showLoader: false,
      errors: {},
    })
  }
  handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleSubmit()
    }
  }
  showPass = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  render() {
    const { errors } = this.state
    return (
      <>
        <Form>
          <div className="position-relative">
            <Form.Group controlId="formBasicEmail" className="mb-3 pb-3">
              <Form.Control
                type="email"
                placeholder="Enter email ID"
                name="username"
                // error={errors.email ? true : false}
                className="login-input"
                onChange={this.handleChange}
                onKeyPress={this.handleEnter}
                onKeyDown={this.handleKeyDown}
                onPaste={this.handlePaste}
                maxLength={CONSTANTS.MAXLENGTH.EMAIL}
              />
              <div className="error alignLeft text-danger position-absolute mt-4 pt-1">
                {errors.email}
              </div>
            </Form.Group>
          </div>

          <div className="position-relative">
            <Form.Group controlId="formBasicPassword">
              <Form.Control
                type={this.state.showPassword ? "text" : "password"}
                // error={errors.password ? true : false}
                placeholder="Enter password"
                name="password"
                className="login-input"
                onKeyPress={this.handleEnter}
                onKeyDown={this.handleKeyDown}
                onPaste={this.handlePaste}
                onChange={this.handleChange}
                maxLength={CONSTANTS.MAXLENGTH.PASSWORD}
              />
              <div
                onClick={() => this.showPass(this.state.showPassword)}
                className="pwd-icons"
              >
                {!this.state.showPassword ? (
                  <div className="pointer">
                    <ShowEyeicon />
                  </div>
                ) : (
                  <div className="pointer">
                    <HideEyeicon />
                  </div>
                )}
              </div>
              <div className="error alignLeft new-error text-danger position-absolute mt-4 pt-2">
                {errors.password}
              </div>
            </Form.Group>
          </div>
          <div className="mb-2 mb-md-0 d-flex justify-content-end">
            <a
              className="auth-link pointer green-btn"
              role="button"
              onClick={this.redirectToForgotPassword}
            >
              Forgot Password ?
            </a>
          </div>
          <Button
            variant="primary"
            type="button"
            className="deliva-btn"
            onClick={() => this.handleSubmit()}
          >
            Submit
          </Button>
        </Form>

        {/* <div className="mrg15-B">
          <TextField
            fullWidth
            error={errors.email ? true : false}
            id="outlined-email"
            name="username"
            label="Email"
            variant="outlined"
            value={username}
            onChange={this.handleChange}
            onKeyPress={this.handleEnter}
            inputProps={{
              maxLength: 60,
            }}
          />
          <div className="error alignLeft">{errors.email}</div>
        </div>
        <div className="mrg1-B">
          <TextField
            fullWidth
            error={errors.password ? true : false}
            id="outlined-password"
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={this.handleChange}
            onKeyPress={this.handleEnter}
            inputProps={{
              maxLength: 30,
            }}
          />
          <div className="error alignLeft">{errors.password}</div>
        </div>

        <div className="alignRight mrg2-B">
          <Button variant="link" onClick={this.redirectToForgotPassword}>
            Forgot Password?
          </Button>
        </div>
        <div className="alignCenter">
          <Button variant="secondary" onClick={() => this.clear()}>
            CLEAR
          </Button>
          <Button
            variant="primary"
            className="mrg1-L"
            onClick={() => this.handleSubmit()}
          >
            LOGIN
          </Button>
          <Loader showLoader={this.state.showLoader} />
        </div> */}
        <Loader showLoader={this.state.showLoader} />
      </>
    );
  }
}

export default connect(null, { actionLogin })(LoginForm)

import React, { Component } from 'react'
import { Row, Col, FormControl } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import moment from 'moment'

import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import Loader from '../../common/Loader'
import delivaApiService from '../../../common/services/apiService'
import { getLocalStorage, range } from '../../../common/helpers/Utils'
import CONSTANTS from '../../../common/helpers/Constants'

class VerificationDAPA extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      totalOrdersCount: '',
      sortBy: '',
      sortOrder: 'desc',
      keyword: '',
      search: '',
      totalOrders: [],
      roleId: '',
    }
  }

  componentDidMount() {
    this.setState(
      {
        roleId: this.props.match.params.id,
      },
      () => {
        this.getList(
          this.state.pageno,
          this.state.records,
          this.state.sortBy,
          this.state.sortOrder
        )
      }
    )
  }

  componentWillReceiveProps(nextprops) {
    this.setState(
      {
        roleId: nextprops.match.params.id,
      },
      () => {
        this.getList(this.state.pageno, this.state.records)
      }
    )
  }

  update() {
    this.getList(
      this.state.pageno,
      this.state.records,
      this.state.sortBy,
      this.state.sortOrder
    )
  }

  onChangeItemPerPage(e) {
    if (e.target.value && e.target.value > 0) {
      this.getList(
        1,
        e.target.value,
        this.state.sortBy,
        this.state.sortOrder,
        true
      )
    } else {
      this.setState({
        records: '',
      })
    }
  }

  handleChangeInput = (e) => {
    this.setState({ search: e.target.value }, () => {
      // if( this.state.search != '' || this.state.search.trim() != ''){
      setTimeout(() => {
        this.getList(
          1,
          this.state.records,
          this.state.sortBy,
          this.state.sortOrder,
          true
        )
      }, 500)
      // }
    })
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.getList(
        1,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    }
  }

  getList(pageno, records, sortBy, sortOrder, loader) {
    this.setState(
      {
        pageno: pageno,
        records: records,
        sortBy: sortBy,
        sortOrder: sortOrder,
      },
      () => {
        if (!loader) this.setLoader(true)
      }
    )
    delivaApiService('getAllPAandDAForApproval', {
      roleId: this.state.roleId,
      records: records == 0 ? CONSTANTS.SHOWRECORDS.NOOFRECORDS : records,
      pageNumber: pageno,
      sortBy: sortBy,
      sortOrder: sortOrder,
      // keyword: keyword,
      searchByProfile: this.state.search,
      userId: getLocalStorage('id'),
    })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.setState(
            {
              totalOrdersCount: response.data.resourceData.processingAgentCount
                ? response.data.resourceData.processingAgentCount
                : response.data.resourceData.deliveryAgentCount,
              totalOrders: response.data.resourceData.deliveryAgents
                ? response.data.resourceData.deliveryAgents
                : response.data.resourceData.processingAgents,
            },
            () => {
              this.getPager(this.state.totalOrdersCount)
              this.setLoader(false)
            }
          )
        } else {
          this.setState({
            totalOrdersCount: 0,
            totalOrders: [],
          })
          this.setLoader(false)
        }
      })
      .catch(
        this.setState({
          // showLoader: false,
        })
      )
  }

  getPager(total) {
    this.setState(
      {
        records:
          this.state.records == 0
            ? CONSTANTS.SHOWRECORDS.NOOFRECORDS
            : this.state.records,
      },
      () => {
        let startPage = this.state.startPage
        let endPage = this.state.endPage
        let totalPage = Math.ceil(total / this.state.records)
        let pageno = this.state.pageno

        if (totalPage <= 5) {
          startPage = 1
          endPage = totalPage
        } else {
          if (pageno <= 3) {
            startPage = 1
            endPage = 5
          } else if (pageno + 1 >= totalPage) {
            startPage = totalPage - 4
            endPage = totalPage
          } else {
            startPage = pageno - 2
            endPage = pageno + 2
          }
        }
        let startIndex = (pageno - 1) * this.state.records
        let endIndex = Math.min(
          startIndex + this.state.records - 1,
          totalPage - 1
        )

        // create an array of pages to ng-repeat in the pager control
        let pageArray
        if (startPage === endPage) {
          pageArray = [1]
        } else {
          pageArray = range(startPage, endPage)
        }
        this.setState({
          // records: this.state.records,
          totalPage: totalPage,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pageArray: pageArray,
        })
      }
    )
  }

  onChangePage(page) {
    if (page === this.state.pageno) {
    } else {
      this.getList(
        page,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    }
  }
  blockUnblock() {
    this.setLoader(true)
    let data = {
      // block: this.state.blockFlag,
      userId: this.state.blockId,
    }
    delivaApiService('blockUnblockCustomer', data).then((response) => {
      console.log(response)
      this.handleInviteVendors('')
      this.setLoader(false)
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    })
  }

  setBlockData(id, flag) {
    this.setState(
      {
        blockId: id,
        blockFlag: flag,
      },
      () => {
        this.props.handleBlockStatus(flag)
      }
    )
  }

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false,
    })
  }
  handleInviteVendors = (id, status) => {
    this.setState({
      showInviteVendors: !this.state.showInviteVendors,
      blockId: id,
      blockedStatus: status,
    })
  }
  setLoader(showLoader) {
    this.setState({
      showLoader,
    })
  }

  sortDeliveryAgent(val) {
    this.setState({ sortOrder: this.state.sortOrder === 'desc' ? '' : 'desc' })
    if (this.state.sortOrder === 'desc') {
      this.getList(this.state.pageno, this.state.records, val, 'asc')
    } else {
      this.getList(this.state.pageno, this.state.records, val, 'desc')
    }
  }

  handleSearch() {
    if (this.state.search.trim() != '') {
      this.getList(
        1,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    }
  }

  render() {
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />
        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3 align-items-center">
                    <Col className="">
                      <h3 className="font-weight-normal mb-0 line-ht color-33 fs-24 fs-24">
                        Verification & Approvals -
                        {this.state.roleId == 3
                          ? ' Delivery Agent'
                          : ' Processing Agent'}{' '}
                        <span className="color-grey">
                          ({this.state.totalOrdersCount})
                        </span>
                      </h3>
                    </Col>
                    <Col md="auto" xl="4" className="text-right">
                      <FormControl
                        type="text"
                        placeholder="Search by Phone Number, Agent Code, Name"
                        className="searchTxt"
                        onChange={(e) => this.handleChangeInput(e)}
                        onKeyDown={this.handleEnter.bind(this)}
                      />
                      <span
                        className="icon-search"
                        onClick={() => this.handleSearch()}
                      ></span>
                    </Col>
                  </Row>
                  <div className="row">
                    <div className="col-sm-12">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              Date{' '}
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.sortDeliveryAgent('date')}
                              ></a>
                            </th>
                            <th>
                              Agent Code{' '}
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() =>
                                  this.sortDeliveryAgent('agentCode')
                                }
                              ></a>
                            </th>
                            <th>
                              {this.state.roleId == 3 ? 'Name' : 'Name'}
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() =>
                                  this.state.roleId == 3
                                    ? this.sortDeliveryAgent('name')
                                    : this.sortDeliveryAgent('name')
                                }
                              ></a>
                            </th>
                            <th>Phone Number </th>
                            <th>Status </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="ttl-order-table">
                          {!this.state.totalOrders.length ? (
                            <tr>
                              <td colSpan={7} style={{ textAlign: 'center' }}>
                                No Records Available
                              </td>
                            </tr>
                          ) : (
                            this.state.totalOrders.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {moment(item.createdDate)
                                      .utc()
                                      .format('DD MMM YYYY')}
                                  </td>
                                  <td>#{item.userId ? item.userId : '-'}</td>
                                  {this.state.roleId == 3 ? (
                                    <td>{item.name ? item.name : '-'}</td>
                                  ) : (
                                    <td>
                                      {item.businessName
                                        ? item.businessName
                                        : '-'}
                                    </td>
                                  )}

                                  <td>
                                    {item.countryCode
                                      ? '+' + item.countryCode
                                      : ' '}
                                    {item.mobile ? ' ' + item.mobile : '-'}
                                  </td>
                                  <td
                                    className="text-green"
                                    style={
                                      item.rejected
                                        ? { color: ' #FF0000' }
                                        : item.active
                                        ? { color: '#7155C1' }
                                        : { color: '#F47249' }
                                    }
                                  >
                                    <div className="flex-active">
                                      <span
                                        className="ab-dot green"
                                        style={
                                          item.rejected
                                            ? { background: ' #FF0000' }
                                            : item.active
                                            ? { background: ' #7155C1' }
                                            : { background: ' #F47249' }
                                        }
                                      ></span>
                                      {item?.rejected
                                        ? 'Rejected'
                                        : item.active === true
                                        ? 'In-process'
                                        : 'Pending'}
                                    </div>
                                  </td>
                                  <td className='views-icos'>
                                    <a
                                      className="pointer"
                                      onClick={() => {
                                        this.state.roleId == 3
                                          ? this.props.history.push({
                                              pathname: '/agent/verify',
                                              state: { item },
                                            })
                                          : this.props.history.push(
                                              '/processingagent/verify',
                                              { item }
                                            )
                                      }}
                                    >
                                      <span className="icon-eye"></span> View
                                    </a>
                                  </td>
                                </tr>
                              )
                            })
                          )}
                        </tbody>
                      </Table>
                      {this.state.totalOrders.length ? (
                        <nav aria-label="Page navigation">
                          <ul className="pagination pg-blue deliva-pagination justify-content-end pr-180p">
                            <li className="page-item">
                              <button
                                className="page-link rotate-180 control-btn"
                                aria-label="Previous"
                                onClick={() =>
                                  this.onChangePage(this.state.pageno - 1)
                                }
                                disabled={
                                  this.state.pageno === 1 ||
                                  this.state.totalPage === 0
                                }
                              >
                                <span className="icon-next"></span>
                                <span
                                  //className="prevNext"
                                  className={`sr-only ${
                                    this.state.pageno === 1 ||
                                    this.state.totalPage === 0
                                      ? ''
                                      : 'active'
                                  }`}
                                >
                                  Previous
                                </span>
                              </button>
                            </li>
                            {this.state.totalPage > 0 &&
                              this.state.pageArray.map((page, ind) => {
                                return (
                                  <li key={ind} className="page-item">
                                    <a
                                      className={`page-link ${
                                        this.state.pageno === page
                                          ? 'active'
                                          : ''
                                      }`}
                                      onClick={() => this.onChangePage(page)}
                                    >
                                      {page}
                                    </a>
                                  </li>
                                )
                              })}

                            <li className="page-item da">
                              <button
                                className="page-link control-btn"
                                aria-label="Next"
                                onClick={() =>
                                  this.onChangePage(this.state.pageno + 1)
                                }
                                disabled={
                                  this.state.pageno === this.state.totalPage ||
                                  this.state.totalPage === 0
                                }
                              >
                                <span className="icon-next"></span>
                                <span
                                  className={`sr-only ${
                                    this.state.pageno ===
                                      this.state.totalPage ||
                                    this.state.totalPage === 0
                                      ? ''
                                      : 'active'
                                  }`}
                                >
                                  Next
                                </span>
                              </button>
                            </li>
                            <li className="page-value">
                              Enter Per Page{' '}
                              <input
                                type="text"
                                placeholder={CONSTANTS.SHOWRECORDS.NOOFRECORDS}
                                name="records"
                                value={this.state.records}
                                onChange={(e) => this.onChangeItemPerPage(e)}
                              />
                            </li>
                          </ul>
                        </nav>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    )
  }
}
export default VerificationDAPA

import React, { Component } from 'react'
import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import { Row, Col, Image, FormControl } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import userblock1 from '../../../assets/images/userblock1.svg'
// import Unblock from "../../../assets/images/Unblock.svg";
import delivaApiService from '../../../common/services/apiService'
import { getLocalStorage, range } from '../../../common/helpers/Utils'
import InputGroup from 'react-bootstrap/InputGroup'
import Loader from '../../common/Loader'
import CONSTANTS from '../../../common/helpers/Constants'

class AdminCustomerTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: false,
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sortOrder: 'desc',
      sortBy: '',
      search: '',
      searchByLocation: '',
      customerList: [],
    }
  }

  componentDidMount() {
    if (getLocalStorage('adminInfo')) {
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    } else {
      this.props.history.push('/login')
    }
  }

  update() {
    this.getList(1, this.state.records, this.state.sortBy, this.state.sortOrder)
  }

  onChangeItemPerPage(e) {
    if (e.target.value && e.target.value > 0) {
      this.getList(1, e.target.value, this.state.sortBy, this.state.sortOrder)
    } else {
      this.setState({
        records: '',
      })
    }
  }

  handleChangeInput = (e) => {
    console.log("EVENT.",e.target.value)
    this.setState({ [e.target.name]: e.target.value }, () => {

      if (
        (this.state.search == '' || this.state.search.trim() == '') &&
        (this.state.searchByLocation == '' ||
          this.state.searchByLocation.trim() == '')
      ) {
        this.getList(
          1,
          this.state.records,
          this.state.sortBy,
          this.state.sortOrder
        )
      }
    })
  }

 
  clearSearch = (data) => {
    this.setState(
      {
        [data]: '',
      },
      () => {
        this.getList(
          this.state.pageno,
          this.state.records,
          this.state.sortBy,
          this.state.sortOrder
        )
      }
    )
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.getList(
        1,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    }
  }
  onChangeSort(val) {
    this.setState({ sortOrder: this.state.sortOrder == 'desc' ? '' : 'desc' })
    if (this.state.sortOrder == 'desc') {
      this.getList(this.state.pageno, this.state.records, val, 'asc')
    } else {
      this.getList(this.state.pageno, this.state.records, val, 'desc')
    }
  }

  handleSearch() {
    console.log("Sjdshfdjg",this.state.search)
      console.log("sfsg")
      this.getList(
        1,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
  
  }

  getList(pageno, records, sortBy, sortOrder) {
    this.setState(
      {
        pageno: pageno,
        records: records,
        sort: sortBy,
        sortOrder: sortOrder,
        customerList: [],
        totalCount: 0,
      },
      () => {
        this.setLoader(true)
      }
    )
    delivaApiService('getAllCustomers', {
      id: getLocalStorage('id'),
      records: records == 0 ? CONSTANTS.SHOWRECORDS.NOOFRECORDS : records,
      pageNumber: pageno,
      sortBy: sortBy,
      sortOrder: sortOrder,
      searchByProfile: this.state.search,
      searchByLocation: this.state.searchByLocation,
    })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.getPager(response.data.resourceData.totalCount)
          this.setState(
            {
              customerList: response.data.resourceData.customerList,
              totalCount: response.data.resourceData.totalCount,
            },
            () => {
              this.setLoader(false)
            }
          )
        } else {
          this.setState({
            customerList: [],
            totalCount: 0,
          })
          this.setLoader(false)
        }
      })
      .catch(
        this.setState({
          // showLoader: false,
        })
      )
  }
  getPager(total) {
    this.setState(
      {
        records:
          this.state.records == 0
            ? CONSTANTS.SHOWRECORDS.NOOFRECORDS
            : this.state.records,
      },
      () => {
        let startPage = this.state.startPage
        let endPage = this.state.endPage
        let totalPage = Math.ceil(total / this.state.records)
        let pageno = this.state.pageno

        if (totalPage <= 5) {
          startPage = 1
          endPage = totalPage
        } else {
          if (pageno <= 3) {
            startPage = 1
            endPage = 5
          } else if (pageno + 1 >= totalPage) {
            startPage = totalPage - 4
            endPage = totalPage
          } else {
            startPage = pageno - 2
            endPage = pageno + 2
          }
        }
        let startIndex = (pageno - 1) * this.state.records
        let endIndex = Math.min(
          startIndex + this.state.records - 1,
          totalPage - 1
        )

        // create an array of pages to ng-repeat in the pager control
        let pageArray
        if (startPage == endPage) {
          pageArray = [1]
        } else {
          pageArray = range(startPage, endPage)
        }
        this.setState({
          // records: this.state.records,
          totalPage: totalPage,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pageArray: pageArray,
        })
      }
    )
  }

  onChangePage(page) {
    if (page == this.state.pageno) {
    } else {
      this.getList(
        page,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    }
  }
  blockUnblock() {
    this.setLoader(true)
    let data = {
      // block: this.state.blockFlag,
      userId: this.state.blockId,
    }
    delivaApiService('blockUnblockCustomer', data).then((response) => {
      this.handleInviteVendors('')
      this.setLoader(false)
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    })
  }
  setBlockData(id, flag) {
    this.setState(
      {
        blockId: id,
        blockFlag: flag,
      },
      () => {
        this.props.handleBlockStatus(flag)
      }
    )
  }

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false,
    })
  }
  handleInviteVendors = (id, status) => {
    this.setState({
      showInviteVendors: !this.state.showInviteVendors,
      blockId: id,
      blockedStatus: status,
    })
  }
  setLoader(showLoader) {
    this.setState({
      showLoader,
    })
  }

  detailsPage = (id, item) => {
    // if(!item.blocked){
      this.props.history.push(`/customerDetails/${id}`, id)
    
    // }
  }
  
    render() {
    return (
      <div className="page__wrapper h-100">       
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <div
                    noGutters
                    className="d-flex mb-3 justify-content-between align-items-center"
                  >
                    <h3 className="font-weight-normal mb-0 fs-24 color-33">
                      Customers{" "}
                      <span className="color-grey">
                        ({this.state.totalCount})
                      </span>
                    </h3>
                    <InputGroup className="mb-2 search-wrap">
                      <InputGroup.Prepend className="search-inner-wrap">
                        <FormControl
                          type="text"
                          placeholder="Search by City/State/Country"
                          className=""
                          name="searchByLocation"
                          value={this.state.searchByLocation}
                          onChange={(e) => this.handleChangeInput(e)}
                          onKeyDown={this.handleEnter.bind(this)}
                        />
                        <span className="icon-search"></span>
                        <a
                          href="#"
                          class="icon-close1 pointer"
                          onClick={(e) => this.clearSearch("searchByLocation")}
                          
                        ></a>
                      </InputGroup.Prepend>
                      <InputGroup className="search-inner-wrap ">
                        {this.state.search == "" ? (
                          <>
                            <FormControl
                              id="inlineFormInputGroup"
                              placeholder="Search by Customer ID/Contact/Name"
                              className="pr-4 fs-lg-13"
                              name="search"
                              value={this.state.search}
                              onChange={(e) => this.handleChangeInput(e)}
                              onKeyDown={this.handleEnter.bind(this)}
                            />
                            <span class="icon-search"></span>
                            <a
                              href="#"
                              class="icon-close1 pointer"
                              onClick={(e) => this.clearSearch("search")}
                            ></a>
                          </>
                        ) : (
                          <>
                            <FormControl
                              id="inlineFormInputGroup"
                              placeholder="Search by Customer Id/Contact/Name"
                              className="pr-5"
                              name="search"
                              value={this.state.search}
                              onChange={(e) => this.handleChangeInput(e)}
                              onKeyDown={this.handleEnter.bind(this)}
                            />
                            {/* <a
                              className="icon-close1 pointer"
                              onClick={(e) => this.clearData(e)}
                            ></a> */}
                            <span class="icon-search"></span>
                            <a
                              href="#"
                              class="icon-close1 pointer"
                              onClick={(e) => this.clearSearch("search")}
                            ></a>
                          </>
                        )}
                      </InputGroup>
                      <button
                        className="find-btn"
                        onClick={() => this.handleSearch()}
                         disabled={!this.state.search && !this.state.searchByLocation}
                      >
                        Find
                      </button>
                    </InputGroup>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              Customer ID{" "}
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort("customerId")}
                              ></a>
                            </th>
                            <th>
                              Name{" "}
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort("name")}
                              ></a>
                            </th>
                            <th>
                              City{" "}
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort("city")}
                              ></a>
                            </th>
                            <th>Contact </th>
                            <th>
                              Status{" "}
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort("status")}
                              ></a>
                            </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="ttl-order-table">
                          {!this.state.customerList.length ? (
                            <tr>
                              <td colSpan={7} style={{ textAlign: "center" }}>
                                No Records Available
                              </td>
                            </tr>
                          ) : (
                            this.state.customerList.map((item, index) => {
                              console.log(item,"ITEMS")
                              return (
                                <tr key={index}>
                                  <td>#{item.userId}</td>
                                  <td className="text-capitalize">
                                    {item.name ? item.name : "-"}
                                  </td>
                                  <td>{item.address ? item.address : "-"}</td>
                                  <td>
                                    {item.mobile
                                      ? `+${item.countryCode}${" "}${
                                          item.mobile
                                        }`
                                      : "-"}
                                  </td>
                                  {!item.blocked ? (
                                    <td className="text-green">
                                      <div className="flex-active">
                                        <span className="ab-dot ab-dot-new green"></span>
                                        Active
                                      </div>

                                      
                                    </td>
                                  ) : (
                                    <td className="text-red">
                                      <div className="flex-active">
                                        <span className="ab-dot ab-dot-new red"></span>
                                        Blocked
                                      </div>
                                    </td>
                                  )}
                                  <td className="views-icos">
                                    {!item.blocked ? (
                                      <a
                                        className="pointer"
                                        onClick={() =>
                                          this.detailsPage(item.userId, item)
                                        }
                                      >
                                        <span className="icon-eye"></span> View
                                      </a>
                                    ) :   <a
                                    className="pointer"
                                    onClick={() =>
                                      this.detailsPage(item.userId, item)
                                    }
                                  >
                                    <span className="icon-eye"></span> View
                                  </a>}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a
                                      className="pointer"
                                      onClick={() =>
                                        this.handleInviteVendors(
                                          item.userId,
                                          item.blocked
                                        )
                                      }
                                    >
                                      {item.blocked == true ? (
                                        <span className="icon-unblockicon"></span>
                                      ) : (
                                        <span className="icon-password"></span>
                                      )}{" "}
                                      {item.blocked == true
                                        ? "Unblock"
                                        : "Block"}
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </Table>
                      {this.state.customerList.length ? (
                        <nav aria-label="Page navigation">
                          <ul className="pagination pg-blue deliva-pagination justify-content-end">
                            <li className="page-item">
                              <button
                                className="page-link rotate-180 control-btn"
                                aria-label="Previous"
                                onClick={() =>
                                  this.onChangePage(this.state.pageno - 1)
                                }
                                disabled={
                                  this.state.pageno == 1 ||
                                  this.state.totalPage == 0
                                }
                              >
                                <span className="icon-next"></span>
                                <span
                                  //className="prevNext"
                                  className={`sr-only ${
                                    this.state.pageno == 1 ||
                                    this.state.totalPage == 0
                                      ? ""
                                      : "active"
                                  }`}
                                >
                                  Previous
                                </span>
                              </button>
                            </li>
                            {this.state.totalPage > 0 &&
                              this.state.pageArray.map((page, ind) => {
                                return (
                                  <li key={ind} className="page-item">
                                    <a
                                      className={`page-link ${
                                        this.state.pageno == page
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => this.onChangePage(page)}
                                    >
                                      {page}
                                    </a>
                                  </li>
                                );
                              })}

                            <li className="page-item">
                              <button
                                className="page-link control-btn"
                                aria-label="Next"
                                onClick={() =>
                                  this.onChangePage(this.state.pageno + 1)
                                }
                                disabled={
                                  this.state.pageno == this.state.totalPage ||
                                  this.state.totalPage == 0
                                }
                              >
                                <span className="icon-next"></span>
                                <span
                                  className={`sr-only ${
                                    this.state.pageno == this.state.totalPage ||
                                    this.state.totalPage == 0
                                      ? ""
                                      : "active"
                                  }`}
                                >
                                  Next
                                </span>
                              </button>
                            </li>
                            <li className="page-value">
                              Enter Per Page{" "}
                              <input
                                type="text"
                                placeholder={CONSTANTS.SHOWRECORDS.NOOFRECORDS}
                                name="records"
                                value={this.state.records}
                                onChange={(e) => this.onChangeItemPerPage(e)}
                              />
                            </li>


                            
                          </ul>
                        </nav>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
        <Modal
          show={this.state.showInviteVendors}
          onHide={() => this.handleInviteVendors("", "")}
          centered
        >
          {/* <Modal.Header closeButton className="border-0">
                
                </Modal.Header> */}
          <Modal.Body>
            <div className="modal-content-wrap text-center">
              <Image src={userblock1} className="img-fluid user-block-img" />
              <h4 className="tertiary-color  mt-3">Are You Sure?</h4>
              <p className="color-grey">
                Are you sure you want to{" "}
                {this.state.blockedStatus == true ? "Unblock" : "Block"} this
                user?{" "}
                {this.state.blockedStatus == false
                  ? "You can not view this profile once blocked."
                  : ""}
              </p>

              <button
                className="cancel-btn mr-3"
                onClick={() => this.handleInviteVendors("")}
              >
                Cancel
              </button>
              <button onClick={() => this.blockUnblock()} className="b1-btn">
                {this.state.blockedStatus == true ? "Unblock" : "Block"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      
    );
  }
}
export default AdminCustomerTable
// export default withRouter(AdminCustomerTable);

import React, { Component } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import matchSorter from 'match-sorter';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

import Sidebar from '../commonAdmin/Sidebar';
import Header from '../commonAdmin/Header';
import Footer from '../commonAdmin/Footer';
import delivaApiService from '../../../common/services/apiService';
import Pagination from '../DeliveryAgent/perfectpage';
import Loader from '../../common/Loader';

class OpenDeliveryRequests extends Component {
  constructor() {
    super();
    this.state = {
      name: 'React',
      searchInput: '',
      details: [],
      data: [],
      defaultPageSize: 5,
      resized: [{ id: 'default', value: 5 }],
      pageSize: 5,
      apiname: '',
    };
    this.filterAll = this.filterAll.bind(this);
  }

  componentDidMount() {   
    this.getListOfOpenRequests();
  }

  handleRouteChange(val) {
    this.props.history.push(val);
  }

  textFilter(filter, row) {
    let result = parseInt(
      row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()),
      10
    );
    if (result < 0) {
      return false;
    } else {
      return true;
    }
  }
  setLoader(showLoader) {
    this.setState({
      showLoader,
    });
  }

  onFilteredChange(filtered) {
    if (filtered.length > 1 && this.state.filterAll.length) {
      const filterAll = '';
      this.setState({
        filtered: filtered.filter((item) => item.id != 'all'),
        filterAll,
      });
    } else this.setState({ filtered });
  }

  onRezise(page) {
    console.log(page);
  }

  filterAll(e) {    
    const { value } = e.target;
    const filterAll = value;
    const filtered = [{ id: 'all', value: filterAll }];  
    this.setState({ filterAll, filtered });
  }

  handleChange = (event) => {   
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;   
    let filteredData = this.state.details.filter((value) => {
      return (
        value?.packageName?.toLowerCase().includes(searchInput?.toLowerCase()) ||
        value?.destinationLocation
          ?.toLowerCase()
          ?.includes(searchInput?.toLowerCase()) ||
        value?.orderId?.toString()?.includes(searchInput)
      );
    });
    this.setState({ data: filteredData });
  };

  getListOfOpenRequests() {
    this.setLoader(true)
    delivaApiService('getListOfOpenRequests')
      .then((response) => {
        if (response && response.data && response.data.resourceData) { 
          this.setLoader(false)      
          this.setState(
            {
              details: response.data.resourceData.data,
              totalCount: response.data.resourceData.count,
            },
            () => {
              this.setState({
                data: this.state.details.map((item) => {
                  return item;
                }),
              });
            }
          );
        } 
      })
      .catch((err) => {
        this.setLoader(false)      
      });      
  }

  hello(pageSize) {
    console.log('hi');
    this.setState({
      pageSize,
    });
  }

  render() {
    const columns = [
      {
        Header: 'Request ID',
        id: 'orderId',        
        accessor: (d) => d.orderId,
        Cell: (props) => <span>#{props.value}</span>, // Custom cell components!
        // accessor: "orderId", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['orderId'] }),
        filterAll: true,
      },

      {
        Header: 'Order Date',
        id: 'orderDate',
        accessor: (d) => moment(new Date(d.orderDate)).format('DD MMM YYYY'),
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "orderDate", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['requestId'] }),
        filterAll: true,
      },

      {
        Header: 'Package Name',
        id: 'packageName',
        accessor: (d) => d.packageName,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "customerName", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['packageName'] }),
        filterAll: true,
      },
     
      {
        Header: 'Destination Location',
        id: 'destinationLocation',      
        accessor: (d) => d.destinationLocation,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "destinationLocation", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['destinationLocation'] }),
        filterAll: true,
      },

      {
        Header: 'Pickup Location',
        id: 'pickUpLocation',       
        accessor: (d) => d.pickUpLocation,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "pickUpLocation", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['pickUpLocation'] }),
        filterAll: true,
      },

      {
        Header: 'Package Weight',
        id: 'packageWeight',
        accessor: (d) => d.packageWeight,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "deliveryAgentName", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['packageWeight'] }),
        filterAll: true,
      },

     
      {
        Header: 'All',
        id: 'all',
        width: 0,
        show: false,
        resizable: false,
        sortable: false,
        Filter: () => {},
        getProps: () => {
          return {};
        },
        filterMethod: (filter, rows) => {
          console.log(filter);
          console.log(rows);
          const result = matchSorter(rows, filter.value, {
            keys: [
              'orderId',
              'destinationLocation',
              'orderDate',
              'pickUpLocation',             
            ],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          });
          console.log('row[0]:', result[0]);
          return result;
        },
        filterAll: true,
      },
    ];
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col>
                      <h3 className="font-weight-normal mb-0 line-ht color-33 fs-24 fs22">
                        Open Delivery Requests
                        <span className="color-grey">
                          ({this.state.totalCount})
                        </span>
                      </h3>
                      <h5 className="font-weight-normal mb-0 line-ht fs18 color-head">
                        <span
                          className="pointer"
                          onClick={() => this.handleRouteChange("/dashboard")}
                        >
                          Dashboard
                        </span>
                        <span className="icon-next mx-2 Fs12"></span>
                        Open Delivery Requests
                      </h5>
                    </Col>
                    <Col md="auto" className="text-right">
                      <input
                        name="searchInput"
                        value={this.state.searchInput || ""}
                        onChange={this.handleChange}
                        label="Search"
                        type="text"
                        placeholder="Search by Request ID, Location"
                        className="searchTxt w-100"
                      />
                      <span className="icon-search"></span>
                    </Col>
                  </Row>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="">
                        {/* table-wrap open-delivery-table */}
                        <ReactTable
                          data={this.state.data}
                          columns={this.state.pageSize ? columns : []}
                          defaultPageSize={this.state.defaultPageSize}
                          pageSize={this.state.pageSize}
                          showPageSizeOptions={true}
                          filtered={this.state.filtered}
                          ref={(r) => (this.reactTable = r)}
                          onFilteredChange={this.onFilteredChange.bind(this)}
                          onPageSizeChange={(pageSize, pageIndex) => {
                            console.log(pageSize, pageIndex);
                          }}
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.age]) === filter.value
                          }
                          PaginationComponent={Pagination}
                        />
                        <span className="page-value">
                          Enter Per Page
                          <input
                            type="text"
                            placeholder="07"
                            name="defaultPageSize"
                            value={this.state.pageSize}
                            onChange={(e) => this.hello(e.target.value)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    );
  }
}
export default OpenDeliveryRequests;

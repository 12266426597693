import React, { Component } from "react";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import delivaApiService from "../../../common/services/apiService";
import { getLocalStorage, range } from "../../../common/helpers/Utils";
import { NavLink } from "react-router-dom";
import Loader from "../../common/Loader";
import CONSTANTS from "../../../common/helpers/Constants";

class CommissionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sortBy: "",
      sortOrder: "",
      search: "",
      orderList: [],
      roleId: "",
      totalCount: 0,
      showLoader: false,
      listFor: "pending",
      // selectedOption: "pending"
    };
  }

  componentDidMount() {
    if (getLocalStorage("adminInfo")) {
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      );
    } else {
      this.props.history.push("/login");
    }
  }
  update() {
    this.getList(
      1,
      this.state.records,
      this.state.sortBy,
      this.state.sortOrder
    );
  }

  onChangeItemPerPage(e) {
    if (e.target.value && e.target.value > 0) {
      this.getList(1, e.target.value, this.state.sortBy, this.state.sortOrder);
    } else {
      this.setState({
        records: "",
      });
    }
  }

  onChangeSort(val) {
    if(this.state.totalCount!==0){
    this.setState({ sortOrder: this.state.sortOrder === "desc" ? "" : "desc" });
    if (this.state.sortOrder === "desc") {
      this.getList(this.state.pageno, this.state.records, val, "asc");
    } else {
      this.getList(this.state.pageno, this.state.records, val, "desc");
    }
  }
  }

  getList(pageno, records, sortBy, sortOrder) {
    this.setState(
      {
        pageno: pageno,
        records: records,
        sortBy: sortBy,
        sortOrder: sortOrder,
        orderList: [],
      },
      () => {
        this.setLoader(true);
      }
    );

    delivaApiService("getReferralList", {
      userId: getLocalStorage("id"),
      size: records,
      page: pageno,
      sortBy: sortBy,
      sortOrder: sortOrder,
      searchKey: this.state.search,
    })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.getPager(response.data.totalRecords);
          this.setState(
            {
              orderList: response.data.resourceData,
              totalCount: response.data.totalRecords,
            },
            () => {
              this.setLoader(false);
            }
          );
        } else {
          this.setLoader(false);
        }
      })
      .catch(this.setLoader(false));
  }
  getPager(total) {
    let startPage = this.state.startPage;
    let endPage = this.state.endPage;
    let totalPage = Math.ceil(total / this.state.records);
    let pageno = this.state.pageno;

    if (totalPage <= 5) {
      startPage = 1;
      endPage = totalPage;
    } else {
      if (pageno <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (pageno + 1 >= totalPage) {
        startPage = totalPage - 4;
        endPage = totalPage;
      } else {
        startPage = pageno - 2;
        endPage = pageno + 2;
      }
    }
    let startIndex = (pageno - 1) * this.state.records;
    let endIndex = Math.min(startIndex + this.state.records - 1, totalPage - 1);

    // create an array of pages to ng-repeat in the pager control
    let pageArray;
    if (startPage === endPage) {
      pageArray = [1];
    } else {
      pageArray = range(startPage, endPage);
    }
    this.setState({
      // records: this.state.records,
      totalPage: totalPage,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pageArray: pageArray,
    });
  }

  handleChangeInput = (e) => {
    this.setState({ search: e.target.value }, () => {
      if (this.state.search === "" || this.state.search.trim() === "") {
        this.getList(
          1,
          this.state.records,
          this.state.sortBy,
          this.state.sortOrder
        );
      }
    });
  };

  onChangePage(page) {
    if (page === this.state.pageno) {
    } else {
      this.getList(
        page,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      );
    }
  }

  handleEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.getList(
        1,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      );
    }
  };

  setLoader(showLoader) {
    this.setState({
      showLoader,
    });
  }

  handleSearch() {
    if (this.state.search.trim() !== "") {
      this.getList(
        1,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      );
    }
  }
  change = (e) => {
    let data = { order: e.target.value };
    this.setState({ listFor: e.target.value }, () => {
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      );
    });
  };

  render() {
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />
        <div className="mainContainer order-list-new-table CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3 align-items-center">
                    <Col md={6} lg={6} className="">
                      <h3 className="font-weight-normal mb-2 mb-lg-0 tertiary-color fs-24">
                        Referral List
                        <span className="tertiary-color">
                          ({this.state.totalCount})
                        </span>
                      </h3>
                    </Col>
                  </Row>

                  <div className="row">
                    <div className="col-sm-12 order-boxlist">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              Referral From
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() =>
                                  this.onChangeSort("referralFrom")
                                }
                              ></a>
                            </th>
                            <th>
                              Referral To
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort("referralTo")}
                              ></a>
                            </th>
                            <th>
                              Date
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort("rewardedAt")}
                              ></a>
                            </th>
                            <th>
                              Points
                              <a
                                className="icon-up-and-down color-light-grey"
                                onClick={() => this.onChangeSort("points")}
                              ></a>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="ttl-order-table">
                          {!this.state.orderList.length ? (
                            <tr>
                              <td colSpan={7} style={{ textAlign: "center" }}>
                                No Records Available
                              </td>
                            </tr>
                          ) : (
                            this.state.orderList.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="text-capitalize">
                                    {item.referralFrom
                                      ? item.referralFrom
                                      : "-"}
                                  </td>
                                  <td className="text-capitalize">
                                    <div className="line-clamp2">
                                      {item.referralTo}
                                    </div>
                                  </td>
                                  <td>
                                    {item?.rewardedAt
                                      ? moment(item?.rewardedAt).format(
                                          "DD MMM YYYY"
                                        )
                                      : "-"}
                                  </td>
                                  <td>{item.points ? item.points : "-"}</td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </Table>
                      {this.state.totalCount === 0 ? null : (
                        <nav aria-label="Page navigation">
                          <ul className="pagination pg-blue deliva-pagination justify-content-end pr-180p">
                            <li className="page-item">
                              <button
                                className="page-link rotate-180 control-btn"
                                aria-label="Previous"
                                onClick={() =>
                                  this.onChangePage(this.state.pageno - 1)
                                }
                                disabled={
                                  this.state.pageno === 1 ||
                                  this.state.totalPage === 0
                                }
                              >
                                <span className="icon-next"></span>
                                <span
                                  //className="prevNext"
                                  className={`sr-only ${
                                    this.state.pageno === 1 ||
                                    this.state.totalPage === 0
                                      ? ""
                                      : "active"
                                  }`}
                                >
                                  Previous
                                </span>
                              </button>
                            </li>
                            {this.state.totalPage > 0 &&
                              this.state.pageArray.map((page, ind) => {
                                return (
                                  <li key={ind} className="page-item">
                                    <a
                                      className={`page-link ${
                                        this.state.pageno === page
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => this.onChangePage(page)}
                                    >
                                      {page}
                                    </a>
                                  </li>
                                );
                              })}

                            <li className="page-item">
                              <button
                                className="page-link control-btn"
                                aria-label="Next"
                                onClick={() =>
                                  this.onChangePage(this.state.pageno + 1)
                                }
                                disabled={
                                  this.state.pageno === this.state.totalPage ||
                                  this.state.totalPage === 0
                                }
                              >
                                <span className="icon-next"></span>
                                <span
                                  className={`sr-only ${
                                    this.state.pageno ===
                                      this.state.totalPage ||
                                    this.state.totalPage === 0
                                      ? ""
                                      : "active"
                                  }`}
                                >
                                  Next
                                </span>
                              </button>
                            </li>
                            <li className="page-value">
                              Enter Per Page{" "}
                              <input
                                type="text"
                                placeholder={CONSTANTS.SHOWRECORDS.NOOFRECORDS}
                                name="records"
                                value={this.state.records}
                                onChange={(e) => this.onChangeItemPerPage(e)}
                              />
                            </li>
                          </ul>
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
        <Loader showLoader={this.state.showLoader} />
      </div>
    );
  }
}
export default CommissionList;

import React, { Component } from 'react';
import { Row, Col, Image, FormControl } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import Sidebar from '../commonAdmin/Sidebar';
import Header from '../commonAdmin/Header';
import Footer from '../commonAdmin/Footer';
import { CONSTANTS } from '../../../common/helpers/Constants';
import delivaApiService from '../../../common/services/apiService';
import { getLocalStorage, range } from '../../../common/helpers/Utils';
import OrderImage1 from '../../../assets/images/OrderImage1.svg';
import userblock1 from '../../../assets/images/userblock1.svg';

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sort: '',
      keyword: '',
      search: '',
      totalOrders: [],
    };
  }

  componentDidMount() {
    if (getLocalStorage('adminInfo')) {
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        this.state.search
      );
    } else {
      this.props.history.push('/login');
    }
  }
  update() {
    this.getList(1, this.state.records, 'desc', 'eventName', '');
  }

  onChangeItemPerPage(e) {
    if (e.target.value && e.target.value > 0) {
      this.getList(
        1,
        e.target.value,
        this.state.sort,
        this.state.keyword,
        this.state.search
      );
    } else {
      this.setState({
        records: '',
      });
    }
  }
  onChangeSearch(e) {
    console.log(e.target.value);
    if (e.target.value.length > 3) {
      this.getList(
        1,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        e.target.value
      );
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
      if (e.target.value.length == 0) {
        this.getList(
          1,
          this.state.records,
          this.state.sort,
          this.state.keyword,
          e.target.value
        );
      }
    }
  }
  handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.getList(
        1,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        this.state.search
      );
    }
  };
  onChangeSort(keyword, sort) {
    if (this.state.keyword == keyword) {
      this.getList(
        this.state.pageno,
        this.state.records,
        sort,
        keyword,
        this.state.search
      );
    } else {
      this.getList(
        // this.state.pageno,
        1, //pagenodoubt
        this.state.records,
        'desc',
        keyword,
        this.state.search
      );
    }
  }
  getList(pageno, records, sort, keyword, search) {
    this.setState(
      {
        pageno: pageno,
        records: records,
        sort: sort,
        keyword: keyword,
        search: search,
      },
      () => {
        console.log('showLoader', this.state.showLoader);
        this.setLoader(true);
      }
    );
    delivaApiService('getAllOrders', {
      id: getLocalStorage('id'),
      upperLimit: records,
      lowerLimit: pageno,
      // sort: sort,
      // keyword: keyword,
      // search: search,
    })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.getPager(response.data.resourceData.totalOrdersCount);
          console.log(response.data.resourceData);
          console.log(response.data.resourceData.totalOrders);
          console.log(response.data.resourceData.totalOrdersCount);

          this.setState(
            {
              totalOrders: response.data.resourceData.totalOrders,
              totalOrdersCount: response.data.resourceData.totalOrdersCount,
            },
            () => {
              console.log(this.state.totalOrders);
              this.setLoader(false);
            }
          );
        } else {
          this.setLoader(false);
        }
      })
      .catch(
        this.setState({
          // showLoader: false,
        })
      );
  }
  getPager(total) {
    let startPage = this.state.startPage;
    let endPage = this.state.endPage;
    let totalPage = Math.ceil(total / this.state.records);
    console.log('totalPage', totalPage);
    let pageno = this.state.pageno;

    if (totalPage <= 5) {
      startPage = 1;
      endPage = totalPage;
    } else {
      if (pageno <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (pageno + 1 >= totalPage) {
        startPage = totalPage - 4;
        endPage = totalPage;
      } else {
        startPage = pageno - 2;
        endPage = pageno + 2;
      }
    }
    let startIndex = (pageno - 1) * this.state.records;
    let endIndex = Math.min(startIndex + this.state.records - 1, totalPage - 1);

    // create an array of pages to ng-repeat in the pager control
    let pageArray;
    if (startPage == endPage) {
      console.log('startPage, endPage', startPage, endPage);

      pageArray = [1];
    } else {
      pageArray = range(startPage, endPage);
      console.log('startPage, endPage', startPage, endPage);
    }
    this.setState({
      // records: this.state.records,
      totalPage: totalPage,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pageArray: pageArray,
    });
  }

  onChangePage(page) {
    console.log(page);
    console.log(this.state.pageno);
    if (page == this.state.pageno) {
    } else {
      this.getList(
        page,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        this.state.search
      );
    }
  }
  blockUnblock() {
    this.setLoader(true);
    let data = {
      // block: this.state.blockFlag,
      userId: this.state.blockId,
    };
    delivaApiService('blockUnblockCustomer', data).then((response) => {
      console.log(response);
      this.handleInviteVendors('');
      this.setLoader(false);
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sort,
        this.state.keyword,
        this.state.search
      );
    });
  }
  setBlockData(id, flag) {
    this.setState(
      {
        blockId: id,
        blockFlag: flag,
      },
      () => {
        this.props.handleBlockStatus(flag);
      }
    );
  }

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false,
    });
  };
  handleInviteVendors = (id, status) => {
    this.setState({
      showInviteVendors: !this.state.showInviteVendors,
      blockId: id,
      blockedStatus: status,
    });
  };
  setLoader(showLoader) {
    this.setState({
      showLoader,
    });
  }

  render() {
    return (
      <div className="page__wrapper h-100">
        <Modal
          show={this.state.showInviteVendors}
          onHide={() => this.handleInviteVendors("", "")}
          centered
        >
          {/* <Modal.Header closeButton className="border-0">
                
                </Modal.Header> */}
          <Modal.Body>
            <div className="modal-content-wrap text-center">
              <Image src={userblock1} className="img-fluid user-block-img" />
              <h4 className="tertiary-color  mt-3">Are You Sure?</h4>
              <p className="color-grey">
                Are you sure you want to{" "}
                {this.state.blockedStatus == true ? "unblock" : "block"} this
                user?{" "}
                {this.state.blockedStatus == false
                  ? "You can not view this profile once blocked."
                  : ""}
              </p>
              <button
                className="cancel-btn mr-3"
                onClick={() => this.handleInviteVendors("")}
              >
                Cancel
              </button>
              <button onClick={() => this.blockUnblock()} className="b1-btn">
                {this.state.blockedStatus == true ? "Unblock" : "Block"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3 align-items-center">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 line-ht fs-24 color-33">
                        Order Details{" "}
                      </h3>
                      <h5 className="font-weight-normal mb-0 line-ht fs18 color-grey">
                        Total Orders{" "}
                        <span className="icon-next ml-2 Fs12"></span> Order
                        Details
                      </h5>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      <FormControl
                        type="text"
                        placeholder="Search by CustomerID, Location, Status, Name"
                        className="searchTxt"
                      />
                      <span className="icon-search"></span>
                    </Col>
                  </Row>
                  <div className="row">
                    <div className="col-sm-12">
                      <section class="main-timeline-section">
                        <div class="conference-timeline-content">
                          <div class="timeline-article timeline-article-top">
                            <div class="content-date">
                              <span className="color-grey">
                                20 Mar 20 at 12:30PM
                              </span>
                            </div>
                            <div class="meta-date">
                              <span className="tick"></span>
                            </div>
                            <div class="content-box">
                              <p class="packageName">
                                Package delivered to Processing Agent
                              </p>
                              <p className="greenish-blue">DA ID: #9714501</p>
                            </div>
                          </div>

                          <div class="timeline-article timeline-article-bottom">
                            <div class="content-date">
                              <p class="packageName">
                                Package delivered to Delivery Agent,
                              </p>
                              <p className="greenish-blue">OPC ID: #1234509</p>
                            </div>
                            <div class="meta-date">
                              <span className="tick"></span>
                            </div>
                            <div class="content-box">
                              <p className="color-grey">21 Mar 20 at 12:30PM</p>
                            </div>
                          </div>
                          <div class="timeline-article timeline-article-top">
                            <div class="content-date">
                              {/* <span>10-09-2017</span> */}
                            </div>
                            <div class="meta-date">
                              <span className="step focused">3</span>
                            </div>
                            <div class="content-box">
                              <p class="packageName">
                                Package delivered to Destination Agent
                              </p>
                              <p className="greenish-blue">DPC ID: #1234611</p>
                            </div>
                          </div>

                          <div class="timeline-article timeline-article-bottom">
                            <div class="content-date">
                              <span>Package delivered to Receiver</span>
                            </div>
                            <div class="meta-date">
                              <span className="step ">4</span>
                            </div>
                            <div class="content-box">
                              {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p> */}
                            </div>
                          </div>
                        </div>
                        <div class="conference-center-line"></div>
                      </section>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="crystal-wrap">
                        <div className="row">
                          <div className="col-sm-6">
                            <h4 className="font-weight-normal mb-0 line-ht fs20 color-33">
                              Crystal Package
                            </h4>
                            <h5 className="font-weight-normal mb-0 fs18 line-ht color-grey">
                              Expected Delivery: Mar 1, 2020
                            </h5>
                          </div>
                          <div className="col-sm-6">
                            <h4 className="font-weight-normal mb-0 line-ht fs18 color-33 text-right color-blue">
                              Order #2012012
                            </h4>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-sm-3 col-lg-2 pl-3">
                            <div className="row">
                              <div className="col-sm-2 px-2">
                                <span className="location-btn"></span>
                              </div>
                              <div className="col-sm-10 px-2">
                                <p className="color-grey fs15 mb-1">
                                  Pickup Location
                                </p>
                                <p className="text-capitalize fs15">Indore</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-lg-8">
                            <hr className="custom-hr" />
                          </div>
                          <div className="col-sm-3 col-lg-2 pl-2 ">
                            <div className="row">
                              <div className="col-sm-2 px-2">
                                <span className="destination-icon"></span>
                              </div>
                              <div className="col-sm-10 pl-1 pr-0">
                                <p className="color-grey fs15 mb-1">
                                  Destination Location
                                </p>
                                <p className="text-capitalize fs15">Indore</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="block-wrap">
                          <div className="block-header">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="OdBlock">
                                  <h5 className="font-weight-normal fs18 heading-color">
                                    Orginating Proccesing Center
                                  </h5>
                                  <p className="color-grey">
                                    Modern Store near vijay nagar, Indore
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <h5 className="font-weight-normal fs18 heading-color">
                                  Destination Proccesig center
                                </h5>
                                <p className="color-grey">
                                  Apollo Shop, AB road Indore
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="block-content">
                            <div className="row">
                              <div className="col-md-4">
                                <p className="mb-0 heading-color">Customer</p>
                                <p className="color-grey">Harry Lobo</p>
                              </div>
                              <div className="col-md-4">
                                <p className="mb-0 heading-color">
                                  Delivery Agent
                                </p>
                                <p className="color-grey">Sean L. Sims</p>
                              </div>
                              <div className="col-md-4">
                                <p className="mb-0 heading-color">Receiver</p>
                                <p className="color-grey">Wilson J. Shultz </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <p className="mb-0 heading-color">
                                  Originating Processing Agent
                                </p>
                                <p className="color-grey">John Methew</p>
                              </div>
                              <div className="col-md-4">
                                <p className="mb-0 heading-color">
                                  Destination Processing Agent
                                </p>
                                <p className="color-grey">William Jack</p>
                              </div>
                              <div className="col-md-4"></div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="cancellation-box">
                              <h2 className="font-weight-normal">
                                Reason for order cancelling
                                <span className="box-reason">
                                  Reason for order cancelling
                                </span>
                              </h2>
                              <h5 className="mt-3">
                                Order Cancelled by Delivery agent
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="link-wrap mt-4">
                          <a href="" className="package-link">
                            Package details
                          </a>
                        </div>
                        <div className="row mt-3">
                          <div className="col-sm-12">
                            <h5 className="font-weight-normal fs18 heading-color">
                              Descriptions
                            </h5>
                            <p className="color-grey">
                              A crystal or crystalline solid is a solid material
                              whose constituents are arranged in a highly
                              ordered microscopic structure, forming a crystal
                              lattice that extends in all directions.
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <h5 className="font-weight-normal fs18 heading-color">
                              Weight
                            </h5>
                            <p className="color-grey">1 KG</p>
                          </div>
                          <div className="col-md-4">
                            <h5 className="font-weight-normal fs18 heading-color">
                              Dimensions
                            </h5>
                            <p className="color-grey">
                              L: 25 CM, W:25 CM, H:10 CM
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="text-center">
                              <button className="show-btn my-3 btnDefault btnPrimary">
                                Show images
                              </button>
                              <div className="image-wrap">
                                <ul>
                                  <li>
                                    <img src={OrderImage1} />
                                  </li>
                                  <li>
                                    <img src={OrderImage1} />
                                  </li>
                                  <li>
                                    <img src={OrderImage1} />
                                  </li>
                                  <li>
                                    <img src={OrderImage1} />
                                  </li>
                                  <li>
                                    <img src={OrderImage1} />
                                  </li>
                                </ul>
                              </div>
                              <hr />
                              <p className="color-green mb-1">
                                Ready to Pickup by
                              </p>
                              <h4 className="color-green">
                                Feb 26, 2020 at 12:05 PM
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default OrderDetails;
// export default withRouter(TotalOrderTable);

import React, { Component } from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import matchSorter from 'match-sorter';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

import Sidebar from '../commonAdmin/Sidebar';
import Header from '../commonAdmin/Header';
import Footer from '../commonAdmin/Footer';
import delivaApiService from '../../../common/services/apiService';
import Pagination from '../DeliveryAgent/perfectpage';
import CONSTANTS from '../../../common/helpers/Constants';
import Template from "../../../common/helpers/Template";
import Loader from '../../common/Loader';
class TotalOrders extends Component {
  constructor() {
    super();
    this.state = {
      name: 'React',
      searchInput: '',
      details: [],
      data: [],
      defaultPageSize: 5,
      resized: [{ id: 'default', value: 5 }],
      pageSize: 5,
      apiname: '',
      showLoader: false,
    };
    this.filterAll = this.filterAll.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        pageName: this.props.match.params.name,
      },
      () => {
        this.getOrders();
      }
    );
  }

  handleRouteChange(val) {
    this.props.history.push(val);
  }

  textFilter(filter, row) {
    let result = parseInt(
      row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()),
      10
    );
    if (result < 0) {
      return false;
    } else {
      return true;
    }
  }

  onFilteredChange(filtered) {
    if (filtered.length > 1 && this.state.filterAll.length) {
      const filterAll = '';
      this.setState({
        filtered: filtered.filter((item) => item.id != 'all'),
        filterAll,
      });
    } else this.setState({ filtered });
  }

  onRezise(page) {
    // console.log(page);
  }

  filterAll(e) {
    const { value } = e.target;
    const filterAll = value;
    const filtered = [{ id: 'all', value: filterAll }];
    this.setState({ filterAll, filtered });
  }

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.details.filter((value) => {
      let dateStr = value.orderDate && moment(value.orderDate).format("YYYY-MM-DD")
      // let str = searchInput && moment(searchInput).format("YYYY-MM-DD")
      return (
        // value.packageName.toLowerCase().includes(searchInput.toLowerCase()) ||
        // value.destinationLocation
        //   .toLowerCase()
        //   .includes(searchInput.toLowerCase()) ||
        value.customerName.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.orderId.toString().includes(searchInput) ||
        // dateStr?.includes(str)
        dateStr?.includes(searchInput)

      );
    });
    this.setState({ data: filteredData });
  };

  getOrders() {
    this.setState(
      {
        apiName:
          this.state.pageName == CONSTANTS.ORDERTYPES.TOTAL
            ? 'getAllOrders'
            : this.state.pageName == CONSTANTS.ORDERTYPES.CANCELLED
              ? 'getListOfCancelledRequests'
              : this.state.pageName == CONSTANTS.ORDERTYPES.INTRANSIT
                ? 'getListOfInTransitRequests'
                : this.state.pageName == CONSTANTS.ORDERTYPES.DELIVERED
                  ? 'getListOfDeliveredRequests'
                  : '',
      },
      () => {
        this.setLoader(true);
        delivaApiService(this.state.apiName)
          .then((response) => {
            this.setLoader(false);
            if (response && response.data && response.data.resourceData) {
              this.setState(
                {
                  details: response.data.resourceData.data,
                  totalCount: response.data.resourceData.count,
                },
                () => {
                  this.setState({
                    data: this.state.details?.map((item) => {
                      return item;
                    }),
                  });
                }
              );
            }
          })
          .catch((err) => {

          });
      }
    );
  }

  hello(pageSize) {
    this.setState({
      pageSize,
    });
  }
  changepath = (path, props) => {
    this.props.history.push(path);
  };

  orderStatus = (status) => {
    if (status === CONSTANTS.ORDER_STATUS.TRANSIT) {
      return 'In transit';
    } else if (status === CONSTANTS.ORDER_STATUS.ACCEPTED) {
      return 'Accepted bid';
    } else if (status === CONSTANTS.ORDER_STATUS.DELIVERED) {
      return 'Delivered';
    } else if (status == CONSTANTS.ORDER_STATUS.CANCELLED) {
      return 'Cancelled';
    } else if (status === CONSTANTS.ORDER_STATUS.REJECTED) {
      return 'Pickup rejected';
    }
  };
  setLoader(showLoader) {
    this.setState({
      showLoader,
    });
  }
  render() {
    const columns = [
      {
        Header: 'Order Date',
        id: 'orderDate',
        accessor: (d) => new Date(d.orderDate),
        Cell: (props) => <span>{moment(props.value).format('DD MMM YYYY')}</span>, // Custom cell components!
        // accessor: "orderDate", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['requestId'] }),
        filterAll: true,
      },
      {
        Header: 'Order ID',
        id: 'orderId',
        accessor: (d) => d.orderId,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "orderId", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['orderId'] }),
        filterAll: true,
      },
      {
        Header: 'Customer',
        id: 'customerName',
        accessor: (d) => d.customerName,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "deliveryAgentName", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['customerName'] }),
        filterAll: true,
      },

      // {
      //   Header: 'Order Name',
      //   id: 'packageName',
      //   accessor: (d) => d.packageName,
      //   Cell: (props) => <span>{props.value}</span>, // Custom cell components!
      //   // accessor: "customerName", // String-based value accessors!
      //   filterMethod: (filter, rows) =>
      //     matchSorter(rows, filter.value, { keys: ['packageName'] }),
      //   filterAll: true,
      // },


      {
        Header: 'OPC',
        id: 'pickUpLocation',
        show: this.state.pageName == CONSTANTS.ORDERTYPES.CANCELLED ? false : true,
        accessor: (d) => d.pickUpLocation,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "pickUpLocation", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['pickUpLocation'] }),
        filterAll: true,
      },
      {
        Header: 'DPC',
        id: 'destinationLocation',
        show: this.state.pageName == CONSTANTS.ORDERTYPES.CANCELLED ? false : true,
        accessor: (d) => d.destinationLocation,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "destinationLocation", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['destinationLocation'] }),
        filterAll: true,
      },

      {
        Header: 'Delivery Agent',
        id: 'deliveryAgentName',
        accessor: (d) => d.deliveryAgentName,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "deliveryAgentName", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['deliveryAgentName'] }),
        filterAll: true,
      },

      {
        Header: 'Receiver',
        id: 'receiverName',
        show: this.state.pageName == CONSTANTS.ORDERTYPES.TOTAL ? false : true,
        accessor: (d) => d.receiverName,
        Cell: (props) => <span>{props.value}</span>, // Custom cell components!
        // accessor: "deliveryAgentName", // String-based value accessors!
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['receiverName'] }),
        filterAll: true,
      },

      {
        Header: 'Status',
        id: 'status',
        show: this.state.pageName == CONSTANTS.ORDERTYPES.TOTAL ? true : false,
        accessor: (d) => this.orderStatus(d.status),
        Cell: (props) => <span className={props.value === "Delivered" ? "text-green" : props.value === "In transit" ? "text-orange" : "text-red"}>
          {props.value}
        </span>,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['status'] }),
        filterAll: true,
      },

      {
        Header: 'Action',
        id: 'orderId',
        // show : this.state.pageName == 'total' || this.state.pageName=='cancelled' ? true : false,
        sortable: false,
        accessor: (d) => d.orderId,
        Cell: (props) => (
          <a
            onClick={() =>
              this.changepath(
                '/' + this.state.pageName + '/details/' + props.value
              )
            }
            className="pointer views-icos"
          >
            <span className="icon-eye"></span> <span>View</span>
          </a>
        ), // Custom cell components!
      },
      {
        Header: 'All',
        id: 'all',
        width: 0,
        show: false,
        resizable: false,
        sortable: false,
        Filter: () => { },
        getProps: () => {
          return {};
        },
        filterMethod: (filter, rows) => {
          const result = matchSorter(rows, filter.value, {
            keys: [
              'destinationLocation',
              'orderDate',
              'pickUpLocation',
              'status',
              'customerName',
            ],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          });
          return result;
        },
        filterAll: true,
      },
    ];
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />
        <Loader showLoader={this.state.showLoader} />
        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 line-ht color-33 fs-24 fs22">
                        {this.state.pageName == CONSTANTS.ORDERTYPES.TOTAL
                          ? Template.TOTALORDERS
                          : this.state.pageName ==
                            CONSTANTS.ORDERTYPES.CANCELLED
                          ? "Cancelled Orders"
                          : this.state.pageName ==
                            CONSTANTS.ORDERTYPES.INTRANSIT
                          ? "In-Transit Orders"
                          : this.state.pageName ==
                            CONSTANTS.ORDERTYPES.DELIVERED
                          ? Template.COMPLETEDORDERS
                          : ""}
                        <span className="color-grey">
                          ({this.state.totalCount})
                        </span>
                      </h3>
                      <h5 className="font-weight-normal mb-0 line-ht fs18 color-head">
                        <span
                          className="pointer"
                          onClick={() => this.handleRouteChange("/dashboard")}
                        >
                          Dashboard
                        </span>

                        <span className="icon-next mx-2 Fs12"></span>
                        {this.state.pageName == CONSTANTS.ORDERTYPES.TOTAL
                          ? Template.TOTALORDERS
                          : this.state.pageName ==
                            CONSTANTS.ORDERTYPES.CANCELLED
                          ? "Cancelled Orders"
                          : this.state.pageName ==
                            CONSTANTS.ORDERTYPES.INTRANSIT
                          ? "In-transit Orders"
                          : this.state.pageName ==
                            CONSTANTS.ORDERTYPES.DELIVERED
                          ? Template.COMPLETEDORDERS
                          : ""}
                      </h5>
                    </Col>
                    <Col md={6} lg={4} className="text-right">
                      <input
                        name="searchInput"
                        value={this.state.searchInput || ""}
                        onChange={this.handleChange}
                        label="Search"
                        type="text"
                        // placeholder={this.state.pageName!=='cancelled' ? "Search by Order ID, OPC, DPC":"Search by Order ID, Order date"}
                        placeholder={"Search by Order ID, Customer Name"}
                        className="searchTxt w-100"
                      />
                      <span className="icon-search"></span>
                    </Col>
                  </Row>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="table-wrap statistics-table">
                        <ReactTable
                          className="inTransitOrderTable text-capitalize"
                          data={this.state.data}
                          columns={this.state.pageSize ? columns : columns}
                          defaultPageSize={this.state.defaultPageSize}
                          pageSize={
                            this.state.pageSize === ""
                              ? this.state.defaultPageSize
                              : this.state.pageSize
                          }
                          showPageSizeOptions={true}
                          filtered={this.state.filtered}
                          ref={(r) => (this.reactTable = r)}
                          onFilteredChange={this.onFilteredChange.bind(this)}
                          onPageSizeChange={(pageSize, pageIndex) => {
                            // console.log(pageSize, pageIndex);
                          }}
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.age]) === filter.value
                          }
                          PaginationComponent={Pagination}
                        />
                        <span className="page-value">
                          Enter Per Page
                          <input
                            type="text"
                            placeholder="05"
                            name="defaultPageSize"
                            value={this.state.pageSize}
                            onChange={(e) => this.hello(e.target.value)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default TotalOrders;

import React, { Component } from "react";
import { Row, Col, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Sidebar from "../commonAdmin/Sidebar";
import Header from "../commonAdmin/Header";
import Footer from "../commonAdmin/Footer";
import userblock1 from "../../../assets/images/userblock1.svg";
import delivaApiService from "../../../common/services/apiService";
import constants from "../../../common/helpers/Constants";
import Template from "../../../common/helpers/Template";
import CONSTANTS from "../../../common/helpers/Constants";
class OrdersDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInviteVendors: false,
      allCustomers: [],
      pageno: 1,
      records: 10,
      sort: "",
      keyword: "",
      search: "",
      showImages: false,
      totalOrders: [],
      details: [],
      showPackageDetails: false,
    };
  }

  componentDidMount() {
    this.setState(
      {
        id: this.props.match.params.id,
        apiName: "getOrderDetailByOrderId",
      },
      () => {
        this.getOrders();
      }
    );
  }

  getOrders() {
    delivaApiService(this.state.apiName, { orderId: this.state.id })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.setState({
            details: response.data.resourceData,
          });
        } else {
          this.setLoader(false);
        }
      })
      .catch((err) => {
        this.setLoader(false);
      });
  }

  handleRouteChange(val) {
    this.props.history.push(val);
  }

  handleCloseConfirmation = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  handleInviteVendors = (id, status) => {
    this.setState({
      showInviteVendors: !this.state.showInviteVendors,
      blockId: id,
      blockedStatus: status,
    });
  };

  setLoader(showLoader) {
    this.setState({
      showLoader,
    });
  }

  handleShowImages = () => {
    this.setState({ showImages: !this.state.showImages });
  };

  packageDetails = () => {
    this.setState({
      showPackageDetails: !this.state.showPackageDetails,
    });
  };

  render() {
    const { details } = this.state;
    return (
      <div className="page__wrapper h-100">
        <Modal
          show={this.state.showInviteVendors}
          onHide={() => this.handleInviteVendors("", "")}
          centered
        >
          <Modal.Body>
            <div className="modal-content-wrap text-center">
              <Image src={userblock1} className="img-fluid user-block-img" />
              <h4 className="tertiary-color  mt-3">Are You Sure?</h4>
              <p className="color-grey">
                Are you sure you want to{" "}
                {this.state.blockedStatus === true ? "unblock" : "block"} this
                user?{" "}
                {this.state.blockedStatus === false
                  ? "You can not view this profile once blocked."
                  : ""}
              </p>

              <button
                className="cancel-btn mr-3"
                onClick={() => this.handleInviteVendors("")}
              >
                Cancel
              </button>
              <button onClick={() => this.blockUnblock()} className="b1-btn">
                {this.state.blockedStatus === true ? "Unblock" : "Block"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 fs22 line-ht tertiary-color pointer">
                        Order Details{" "}
                      </h3>
                      <h5 className="font-weight-normal mb-0 fs18 line-ht color-grey">
                        <span
                          className="pointer"
                          onClick={() => {
                            let route;

                            switch (this.props.match.params.name) {
                              case "total":
                                route = "/orders/total";
                                break;
                              case "intransit":
                                route = "/orders/intransit";
                                break;
                              case "cancelled":
                                route = "/orders/cancelled";
                                break;
                              case "delivered":
                                route = "/orders/delivered";
                                break;
                              default:
                                route = "/orders/total";
                                let daId = this.state.details?.daId;
                                route = `/deliveryagentorders/totalorders/${daId}`;
                                // Handle cases for other order statuses or provide a default route
                                break;
                            }

                            this.handleRouteChange(route);
                          }}
                        >
                          {this.props.match.params.name === "total"
                            ? "Total orders"
                            : this.props.match.params.name === "intransit"
                            ? "In Transit orders"
                            : this.props.match.params.name === "cancelled"
                            ? "Cancelled orders"
                            : this.props.match.params.name === "delivered"
                            ? Template.COMPLETEDORDERS
                            : "Total orders"}
                        </span>
                        {/* {this.props.match.params.name == "total"
                          ? <span
                          className="pointer"
                          onClick={() =>
                            this.handleRouteChange("/admin/orders/delivered")
                          }
                        >
                          Total Orders{" "}
                        </span>
                          :  ""} */}
                        <span className="icon-next ml-2 Fs12"></span>{" "}
                        {/* {this.props.match.params.name == "total"
                          ? "Total Orders"
                          : this.props.match.params.name == "cancelled"
                          ? "Cancelled Orders"
                          : this.props.match.params.name == "intransit"
                          ? "InTransit Orders"
                          : this.props.match.params.name == "delivered"
                          ? "Delivered Orders"
                          : ""} */}
                        {"Order details"}
                      </h5>
                    </Col>
                    {/* <Col md={6} lg={4} className="text-right">
                      <FormControl
                        type="text"
                        placeholder="Search by CustomerID, Location, Status, Name"
                        className="searchTxt"
                      />
                      <span className="icon-search"></span>
                    </Col> */}
                  </Row>

                  <div className="row">
                    <div className="col-sm-12">
                      {details.requestType ===
                      CONSTANTS.ORDERTYPE.INTERNATIONAL ? (
                        <section className="main-timeline-section">
                          <div className="conference-center-line"></div>
                          <div className="conference-timeline-content">
                            <div
                              className={
                                "timeline-article timeline-article-top"
                              }
                            >
                              <div className="content-date">
                                <span className="color-grey">
                                  {details?.deliveryDateToDA
                                    ? moment(
                                        new Date(details?.deliveryDateToDA)
                                      ).format("D MMM YY [at] h:mm A")
                                    : "-"}
                                </span>
                              </div>
                              <div className="meta-date">
                                {(() => {
                                  if (
                                    details?.currentLocation === "OPC" ||
                                    details?.currentLocation === "DA" ||
                                    details?.currentLocation === "DPC" ||
                                    details?.currentLocation === "RC"
                                  ) {
                                    return <span className="tick"></span>;
                                  } else {
                                    return <span className="step">1</span>;
                                  }
                                })()}
                              </div>

                              <div class="content-box">
                                <p class="packageName">
                                  Package delivered to Processing Agent
                                </p>
                                <p className="greenish-blue">
                                  OPC ID:
                                  <span class="underline">
                                    #
                                    {details && details?.sourcePa
                                      ? details?.sourcePa?.paId
                                      : ""}
                                  </span>
                                </p>
                              </div>
                            </div>

                            <div class="timeline-article timeline-article-bottom">
                              <div
                                class={`content-date ${
                                  details?.currentLocation === "DPC" ||
                                  details?.currentLocation === "RC" ||
                                  details?.currentLocation === "DA"
                                    ? "color-blue"
                                    : null
                                }`}
                              >
                                <p class="packageName">
                                  Package delivered to Delivery Agent,
                                </p>
                                <p className="greenish-blue">
                                  DA ID:{" "}
                                  <span class="underline">
                                    #{details?.daId ? details?.daId : "-"}
                                  </span>
                                </p>
                              </div>
                              <div class="meta-date">
                                {(() => {
                                  if (
                                    details?.currentLocation === "DPC" ||
                                    details?.currentLocation === "DA" ||
                                    details?.currentLocation === "RC"
                                  ) {
                                    return <span className="tick"></span>;
                                  } else {
                                    return <span className="step">2</span>;
                                  }
                                })()}
                              </div>
                              <div class="content-box">
                                <p className="color-grey">
                                  {details?.deliveryDateToOPC
                                    ? moment(
                                        new Date(details?.deliveryDateToOPC)
                                      ).format("D MMM YY [at] h:mm A")
                                    : "-"}
                                </p>
                              </div>
                            </div>

                            <div class="timeline-article timeline-article-bottom">
                              <div
                                class={`content-date ${
                                  details?.currentLocation === "DPC" ||
                                  details?.currentLocation === "RC"
                                    ? "color-blue"
                                    : null
                                }`}
                              >
                                <p class="packageName">
                                  Package delivered to Destination Agent
                                </p>
                                <p className="greenish-blue">
                                  DPC ID:{" "}
                                  <span class="underline">
                                    #
                                    {details && details?.sourcePa
                                      ? details?.destinationPa?.paId
                                      : ""}
                                  </span>
                                </p>
                              </div>

                              <div class="meta-date">
                                {(() => {
                                  if (
                                    details?.currentLocation === "DPC" ||
                                    details?.currentLocation === "RC"
                                  ) {
                                    return <span className="tick"></span>;
                                  } else {
                                    return <span className="step">3</span>;
                                  }
                                })()}
                              </div>
                              <div class="content-box">
                                <p className="color-grey">
                                  {details?.deliveryDateToDPC
                                    ? moment(
                                        new Date(details?.deliveryDateToDPC)
                                      ).format("D MMM YY [at] h:mm A")
                                    : "-"}
                                </p>
                              </div>
                            </div>

                            <div class="timeline-article timeline-article-bottom">
                              <div
                                class={`content-date ${
                                  details?.currentLocation === "RC"
                                    ? "color-blue"
                                    : null
                                }`}
                              >
                                <span>Package delivered to Receiver</span>
                              </div>
                              <div class="meta-date">
                                {(() => {
                                  if (details?.currentLocation === "RC") {
                                    return <span className="tick"></span>;
                                  } else {
                                    return <span className="step">4</span>;
                                  }
                                })()}
                              </div>
                              <div class="content-box"></div>
                            </div>

                            {/* <div class={"timeline-article timeline-article-top"}>
                            <div class="content-date">
                              <span className="color-grey">
                                {details?.deliveryDateToDA
                                  ? moment(
                                    new Date(details?.deliveryDateToDA)
                                  ).format("D MMM YY [at] h:mm A")
                                  : "-"}
                              </span>
                            </div>
                            <div className="meta-date">
                              {(() => {
                                if (
                                  details?.status == "IN_TRANSIT" &&
                                  details?.currentLocation == "OPC"
                                ) {
                                  return (
                                    <span className="step focused">1</span>
                                  );
                                } else if (
                                  (details?.status == "IN_TRANSIT" ||
                                    details?.status == "PICKUP_VERIFIED" ||
                                    details?.status == "DELIVERED") &&
                                  (details?.currentLocation == "DA" ||
                                    details?.currentLocation == "DPC" ||
                                    details?.currentLocation == "RC")
                                ) {
                                  return <span className="tick"></span>;
                                } else {
                                  return <span className="step">1</span>;
                                }
                              })()}
                            </div>

                            <div className="content-box">
                              <p className='packageName'>Package delivered to Processing Agent</p>
                              <p className="greenish-blue">
                                OPC ID:
                                <span className='underline'>#
                                  {details && details?.sourcePa
                                    ? details?.sourcePa?.paId
                                    : ""}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="timeline-article timeline-article-bottom">
                            <div className={`content-date ${((details?.status == "IN_TRANSIT" ||
                                details?.status == "PICKUP_VERIFIED") &&
                                (details?.currentLocation == "DPC" ||
                                  details?.currentLocation == "RC")) ? 'color-blue' : null
                              }`}>
                              <p className='packageName'>Package delivered to Delivery Agent,</p>
                              <p className="greenish-blue">
                                DA ID: <span className='underline'>#{details?.daId ? details?.daId : "-"}</span>
                              </p>
                            </div>
                            <div className="meta-date">
                              {(() => {
                                if (
                                  details?.status == "IN_TRANSIT" &&
                                  details?.currentLocation == "DA"
                                ) {
                                  return (
                                    <span className="step focused">2</span>
                                  );
                                } else if (
                                  (details?.status == "IN_TRANSIT" ||
                                    details?.status == "PICKUP_VERIFIED" ||
                                    details?.status == "DELIVERED") &&
                                  (details?.currentLocation == "DPC" ||
                                    details?.currentLocation == "RC")
                                ) {
                                  return <span className="tick"></span>;
                                } else {
                                  return <span className="step">2</span>;
                                }
                              })()}
                            </div>
                            <div className="content-box">
                              <p className="color-grey">
                                {details?.deliveryDateToOPC
                                  ? moment(
                                    new Date(details?.deliveryDateToOPC)
                                  ).format("D MMM YY [at] h:mm A")
                                  : "-"}
                              </p>
                            </div>
                          </div>

                          <div className="timeline-article timeline-article-bottom">
                            <div className={`content-date ${((details?.status == "IN_TRANSIT" ||
                                details?.status == "PICKUP_VERIFIED" ||
                                details?.status == "DELIVERED") &&
                                (details?.currentLocation == "DPC" ||
                                  details?.currentLocation == "RC")) ? 'color-blue' : null
                              }`}>
                              <p className='packageName'>Package delivered to Destination Agent</p>
                              <p className="greenish-blue">
                                DPC ID: <span className='underline'>#
                                  {details && details?.sourcePa
                                    ? details?.destinationPa?.paId
                                    : ""}</span>
                              </p>
                            </div>

                            <div className="meta-date">
                              {(() => {
                                if (
                                  details?.status == "IN_TRANSIT" &&
                                  details?.currentLocation == "DPC"
                                ) {
                                  return <span className="step focused">3</span>;
                                } else if (
                                  details?.status == "PICKUP_VERIFIED" &&
                                  details?.currentLocation == "DPC"
                                ) {
                                  return <span className="tick"></span>;
                                } else if (
                                  (details?.status == "PICKUP_VERIFIED" ||
                                    details?.status == "DELIVERED") &&
                                  details?.currentLocation == "RC"
                                ) {
                                  return <span className="tick"></span>;
                                } else {
                                  return <span className="step">3</span>;
                                }
                              })()}
                            </div>
                            <div className="content-box">
                              <p className="color-grey">
                                {details?.deliveryDateToDPC
                                  ? moment(
                                    new Date(details?.deliveryDateToDPC)
                                  ).format("D MMM YY [at] h:mm A")
                                  : "-"}
                              </p>
                            </div>
                          </div>

                          <div className="timeline-article timeline-article-bottom">
                            <div className={`content-date ${((details?.status == "IN_TRANSIT" ||
                                details?.status == "PICKUP_VERIFIED" ||
                                details?.status == "DELIVERED") &&
                                (details?.currentLocation == "DPC" ||
                                  details?.currentLocation == "RC")) ? 'color-blue' : null
                              }`}>
                              <span>Package delivered to Receiver</span>
                            </div>
                            <div className="meta-date">
                              {(() => {
                                if (
                                  details?.status == "PICKUP_VERIFIED" &&
                                  details?.currentLocation == "DPC"
                                ) {
                                  return (
                                    <span className="step focused">4</span>
                                  );
                                } else if (
                                  (details?.status == "PICKUP_VERIFIED" ||
                                    details?.status == "DELIVERED") &&
                                  details?.currentLocation == "RC"

                                ) {
                                  return <span className="tick"></span>;
                                } else {
                                  return <span className="step">4</span>;
                                }
                              })()}
                            </div>
                            <div class="content-box">
                            </div>
                          </div> */}
                          </div>
                        </section>
                      ) : (
                        // {/* local delivery start */}
                        <section className="main-timeline-section local-delivery">
                          <div className="conference-center-line"></div>
                          <div className="conference-timeline-content">
                            <div class="timeline-article timeline-article-bottom">
                              <div
                                class={`content-date ${
                                  details?.currentLocation === "DPC" ||
                                  details?.currentLocation === "RC" ||
                                  details?.currentLocation === "DA"
                                    ? "color-blue"
                                    : null
                                }`}
                              >
                                <p class="packageName">
                                  Package delivered to Delivery Agent,
                                </p>
                                <p className="greenish-blue">
                                  DA ID:{" "}
                                  <span class="underline">
                                    #{details?.daId ? details?.daId : "-"}
                                  </span>
                                </p>
                              </div>
                              <div class="meta-date">
                                {(() => {
                                  if (
                                    details?.currentLocation === "DPC" ||
                                    details?.currentLocation === "DA" ||
                                    details?.currentLocation === "RC"
                                  ) {
                                    return <span className="tick"></span>;
                                  } else {
                                    return <span className="step">1</span>;
                                  }
                                })()}
                              </div>
                              <div class="content-box">
                                <p className="color-grey">
                                  {details?.deliveryDateToDA
                                    ? moment(
                                        new Date(details?.deliveryDateToDA)
                                      ).format("D MMM YY [at] h:mm A")
                                    : "-"}
                                </p>
                              </div>
                            </div>

                            <div class="timeline-article timeline-article-bottom">
                              <div
                                class={`content-date ${
                                  details?.currentLocation === "RC"
                                    ? "color-blue"
                                    : null
                                }`}
                              >
                                <span>Package delivered to Receiver</span>
                              </div>
                              <div class="meta-date">
                                {(() => {
                                  if (details?.currentLocation === "RC") {
                                    return <span className="tick"></span>;
                                  } else {
                                    return <span className="step">2</span>;
                                  }
                                })()}
                              </div>
                              <div class="content-box">
                                <p className="color-grey">
                                  {details?.deliveryDateToRC
                                    ? moment(
                                        new Date(details?.deliveryDateToRC)
                                      ).format("D MMM YY [at] h:mm A")
                                    : "-"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </section>
                      )}

                      {/* local delivery end */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="crystal-wrap">
                        <div className="row">
                          <div className="col-sm-6">
                            <h4 className="font-weight-normal fs20 mb-0 line-ht color-33">
                              {details?.title}
                            </h4>
                            <h5 className="font-weight-normal fs18 mb-0 line-ht color-grey">
                              Expected Delivery:{" "}
                              {details?.expectedDeliveryDate
                                ? moment(
                                    new Date(details?.expectedDeliveryDate)
                                  ).format("DD MMM YY")
                                : "-"}
                            </h5>
                          </div>
                          <div className="col-sm-6">
                            <h4 className="font-weight-normal mb-0 line-ht fs18 color-33 text-right color-blue">
                              Order #
                              {details?.requestId ? details?.requestId : "-"}
                            </h4>
                          </div>
                        </div>
                        <div className="mt-3 d-flex align-items-center gap-3">
                          <div className="d-flex align-items-center">
                            <span className="location-btn"></span>
                            <div>
                              <p className="color-grey fs15 mb-0 text-nowrap">
                                Pickup Location
                              </p>
                              <p className="text-capitalize fs15 mb-0">
                                {details.requestType ===
                                CONSTANTS.ORDERTYPE.LOCAL
                                  ? details.sourceCity
                                  : details?.sourcePa && details?.sourcePa?.city
                                  ? details?.sourcePa?.city
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <hr className="custom-hr mx-3" />
                          <div className="d-flex align-items-center">
                            <span className="destination-icon"></span>
                            <div>
                              <p className="color-grey fs15 mb-0 text-nowrap">
                                Destination Location
                              </p>
                              <p className="text-capitalize fs15 mb-0">
                                {" "}
                                {details.requestType ===
                                CONSTANTS.ORDERTYPE.LOCAL
                                  ? details.destinationCity
                                  : details?.destinationPa &&
                                    details?.destinationPa?.city
                                  ? details?.destinationPa?.city
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                        {this.props.match.params.name == "cancelled" ? (
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="cancellation-box">
                                <h2 className="font-weight-normal">
                                  <span className="box-reason">
                                    Reason for order cancelling
                                  </span>
                                </h2>
                                <h5 className="mt-3">
                                  Order Cancelled
                                  {details?.cancelledByRole ===
                                  constants.ROLES.DELIVERY_AGENT
                                    ? " by Delivery agent"
                                    : details?.cancelledByRole ===
                                      constants.ROLES.PROCESSING_AGENT
                                    ? "by Proccesing agent"
                                    : details?.cancelledByRole ===
                                      constants.ROLES.CUSTOMER
                                    ? " by Customer"
                                    : null}
                                </h5>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="link-wrap mt-4">
                          <span
                            className="color-blue package-link pointer kanit-font fw500"
                            onClick={this.packageDetails}
                          >
                            Package details
                          </span>
                        </div>

                        {/* <div className="details-wrap rounded-4 bg-gray shadow-0 mb-4 mt-4">
                          <Row noGutters>
                            <Col
                              md={12}
                              lg={12}
                              className=""
                              onClick={this.packageDetails}
                            >
                              <h5 className="px-4 fs-20 pointer kanit-font fw500">
                                Package details
                              </h5>
                              <hr />
                            </Col>
                          </Row>
                        </div> */}
                        <br />
                        {this.state.showPackageDetails ? (
                          <div className="block-wrap">
                            {details.requestType ===
                            CONSTANTS.ORDERTYPE.INTERNATIONAL ? (
                              <div className="block-header">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="OdBlock">
                                      <h5 className="font-weight-normal fs18 heading-color">
                                        Orginating Proccesing Center
                                      </h5>
                                      <p className="color-grey">
                                        {details?.sourcePa &&
                                        details?.sourcePa?.businessName
                                          ? details?.sourcePa?.businessName
                                          : "-"}
                                        ,{" "}
                                        {details?.sourcePa &&
                                        details?.sourcePa?.city
                                          ? details?.sourcePa?.city
                                          : "-"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <h5 className="font-weight-normal fs18 heading-color">
                                      Destination Proccesing center
                                    </h5>
                                    <p className="color-grey">
                                      {details?.destinationPa &&
                                      details?.destinationPa?.businessName
                                        ? details?.destinationPa?.businessName
                                        : "-"}
                                      ,{" "}
                                      {details?.destinationPa &&
                                      details?.destinationPa?.city
                                        ? details?.destinationPa?.city
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="block-header">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="OdBlock">
                                      <h5 className="font-weight-normal fs18 heading-color">
                                        Source Address
                                      </h5>
                                      <p className="color-grey">
                                        {details?.sourceLocality
                                          ? details?.sourceLocality
                                          : "-"}
                                        ,{" "}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <h5 className="font-weight-normal fs18 heading-color">
                                      Destination Address
                                    </h5>
                                    <p className="color-grey">
                                      {details?.destinationLocality
                                        ? details?.destinationLocality
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="block-content">
                              <div className="row">
                                <div className="col-md-4">
                                  <p className="mb-0 heading-color">Customer</p>
                                  <p className="color-grey">
                                    {details?.customerName}
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p className="mb-0 heading-color">
                                    Delivery Agent
                                  </p>
                                  <p className="color-grey">
                                    {details?.daName ? details?.daName : "-"}
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p className="mb-0 heading-color">Receiver</p>
                                  <p className="color-grey">
                                    {details?.recieverName
                                      ? details?.recieverName
                                      : "-"}{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="row">
                                {details.requestType ===
                                CONSTANTS.ORDERTYPE.LOCAL ? null : (
                                  <div className="col-md-4">
                                    <p className="mb-0 heading-color">
                                      Originating Processing Agent
                                    </p>
                                    <p className="color-grey">
                                      {details?.sourcePa &&
                                        details?.sourcePa?.businessName}
                                    </p>
                                  </div>
                                )}
                                {details.requestType ===
                                CONSTANTS.ORDERTYPE.LOCAL ? null : (
                                  <div className="col-md-4">
                                    <p className="mb-0 heading-color">
                                      Destination Processing Agent
                                    </p>
                                    <p className="color-grey">
                                      {details?.destinationPa &&
                                        details?.destinationPa?.businessName}
                                    </p>
                                  </div>
                                )}
                                <div className="col-md-4">
                                  <p className="mb-0 heading-color">Status</p>
                                  <p className="color-grey">
                                    {(() => {
                                      if (
                                        details?.status ===
                                        constants.ORDER_STATUS.TRANSIT
                                      ) {
                                        return "In transit";
                                      } else if (
                                        details?.status ===
                                        constants.ORDER_STATUS.ACCEPTED
                                      ) {
                                        return "Accepted bid";
                                      } else if (
                                        details?.status ===
                                        constants.ORDER_STATUS.DELIVERED
                                      ) {
                                        return "Delivered";
                                      } else if (
                                        details?.status ==
                                        constants.ORDER_STATUS.CANCELLED
                                      ) {
                                        return "Cancelled";
                                      } else if (
                                        details?.status ===
                                        constants.ORDER_STATUS.REJECTED
                                      ) {
                                        return "Pickup rejected";
                                      } else if (
                                        details?.status ===
                                        constants.ORDER_STATUS.VERIFIED
                                      ) {
                                        return "Pickup verified";
                                      }
                                    })()}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="row mt-4">
                          <div className="col-sm-12">
                            <h5 className="font-weight-normal fs18 heading-color">
                              Descriptions
                            </h5>
                            <p className="color-grey mb-0">
                              {details?.description}
                            </p>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-4">
                            <h5 className="font-weight-normal fs18 heading-color">
                              Weight
                            </h5>
                            <p className="color-grey mb-0">
                              {details?.weight} {details.wtUnit}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <h5 className="font-weight-normal heading-color">
                              Dimensions
                            </h5>
                            <p className="color-grey">
                              L: {details?.length} {details?.dmUnit}, W:
                              {details?.weight} {details?.dmUnit}, H:
                              {details?.height} {details?.dmUnit}
                            </p>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-sm-12">
                            <div className="text-center">
                              <button
                                className={`btnDefault btnPrimary mb-3`}
                                onClick={() => this.handleShowImages()}
                              >
                                {this.state.showImages
                                  ? "Hide images"
                                  : "Show Images"}
                              </button>

                              {this.state.showImages &&
                              details &&
                              details?.mediaUrls ? (
                                <div className="image-wrap">
                                  <ul>
                                    {details?.mediaUrls.map((item, index) => {
                                      return (
                                        <li key={Math.random()}>
                                          <img
                                            src={item.thumbnailUrl350X350}
                                            className="img-fluid img-wraps"
                                          />
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              ) : (
                                ""
                              )}
                              <hr />
                              <p className="color-green pickup-title">
                                Ready to Pickup by
                              </p>
                              <h4 className="color-green pickup-text">
                                {" "}
                                {details?.dropoffDate
                                  ? moment(
                                      new Date(details?.dropoffDate)
                                    ).format("D MMM YY [at] h:mm A")
                                  : "-"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default OrdersDetails;

import React, { Component } from 'react'
import Sidebar from '../commonAdmin/Sidebar'
import Header from '../commonAdmin/Header'
import Footer from '../commonAdmin/Footer'
import { Row, Col, Image, FormControl } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import userblock1 from '../../../assets/images/userblock1.svg'
import { ReactComponent as Debit } from '../../../assets/images/debit.svg'
import { ReactComponent as Credit } from '../../../assets/images/credit.svg'
// import Unblock from "../../../assets/images/Unblock.svg";
import delivaApiService from '../../../common/services/apiService'
import { getLocalStorage, range } from '../../../common/helpers/Utils'
import { withRouter } from 'react-router-dom'
import Loader from '../../common/Loader'

import OrangeCard from '../../../assets/images/OrangeCard.svg'
import BlueCard from '../../../assets/images/BlueCard.svg'
import PinkCard from '../../../assets/images/PinkCard.svg'
import moment from 'moment'
import CONSTANTS from '../../../common/helpers/Constants'

class Accounts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transactionHistory: [],
      showLoader: false,
      pageno: 1,
      records: 10,
      sortBy: '',
      sortOrder: '',
      search: '',
      orderList: [],
      roleId: '',
      totalCount: 0,
    }
  }

  componentDidMount() {
    if (getLocalStorage('adminInfo')) {
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    } else {
      this.props.history.push('/login')
    }
  }
  // update() {
  //   this.getTransactionHistory();
  // }
  update() {
    this.getList(1, this.state.records, this.state.sortBy, this.state.sortOrder)
  }

  onChangeItemPerPage(e) {
    if (e.target.value && e.target.value > 0) {
      this.getList(1, e.target.value, this.state.sortBy, this.state.sortOrder)
    } else {
      this.setState({
        records: '',
      })
    }
  }

  onChangeSort(val) {
    this.setState({ sortOrder: this.state.sortOrder === 'desc' ? '' : 'desc' })
    if (this.state.sortOrder === 'desc') {
      this.getList(this.state.pageno, this.state.records, val, 'asc')
    } else {
      this.getList(this.state.pageno, this.state.records, val, 'desc')
    }
  }

  getList(pageno, records, sortBy, sortOrder) {
    this.setState(
      {
        pageno: pageno,
        records: records,
        sortBy: sortBy,
        sortOrder: sortOrder,
        orderList: [],
      },
      () => {
        this.setLoader(true)
      }
    )

    delivaApiService('getAllTransactionHistory', {
      userId: getLocalStorage('id'),
      size: records,
      page: pageno,
      // sortBy: sortBy,
      // sortOrder: sortOrder,
      // searchKey: this.state.search,
    })
      .then((response) => {
        if (response && response.data && response.data.resourceData) {
          this.getPager(response.data.totalRecords)
          this.setState(
            {
              transactionHistory: response.data.resourceData,
              totalCount: response.data.totalRecords,
            },
            () => {
              this.setLoader(false)
            }
          )
        } else {
          this.setLoader(false)
        }
      })
      .catch(this.setLoader(false))
  }
  getPager(total) {
    let startPage = this.state.startPage
    let endPage = this.state.endPage
    let totalPage = Math.ceil(total / this.state.records)
    let pageno = this.state.pageno

    if (totalPage <= 5) {
      startPage = 1
      endPage = totalPage
    } else {
      if (pageno <= 3) {
        startPage = 1
        endPage = 5
      } else if (pageno + 1 >= totalPage) {
        startPage = totalPage - 4
        endPage = totalPage
      } else {
        startPage = pageno - 2
        endPage = pageno + 2
      }
    }
    let startIndex = (pageno - 1) * this.state.records
    let endIndex = Math.min(startIndex + this.state.records - 1, totalPage - 1)

    // create an array of pages to ng-repeat in the pager control
    let pageArray
    if (startPage === endPage) {
      pageArray = [1]
    } else {
      pageArray = range(startPage, endPage)
    }
    this.setState({
      // records: this.state.records,
      totalPage: totalPage,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pageArray: pageArray,
    })
  }

  handleChangeInput = (e) => {
    this.setState({ search: e.target.value }, () => {
      if (this.state.search === '' || this.state.search.trim() === '') {
        this.getList(
          1,
          this.state.records,
          this.state.sortBy,
          this.state.sortOrder
        )
      }
    })
  }

  onChangePage(page) {
    if (page === this.state.pageno) {
    } else {
      this.getList(
        page,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    }
  }

  handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.getList(
        1,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    }
  }

  setLoader(showLoader) {
    this.setState({
      showLoader,
    })
  }

  handleSearch() {
    if (this.state.search.trim() !== '') {
      this.getList(
        1,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    }
  }
  change = (e) => {
    let data = { order: e.target.value }
    this.setState({ listFor: e.target.value }, () => {
      this.getList(
        this.state.pageno,
        this.state.records,
        this.state.sortBy,
        this.state.sortOrder
      )
    })
  }
  // getTransactionHistory() {
  //   this.setState({
  //     showLoader: true,
  //   });
  //   delivaApiService("getAllTransactionHistory", {
  //     userId: getLocalStorage("id"),
  //     page: this.state.pageno,
  //     size: this.state.records
  //   })
  //     .then((response) => {
  //       console.log(response);
  //       if (response && response.data && response.data.resourceData) {
  //         this.setState(
  //           {
  //             transactionHistory: response.data.resourceData,
  //           },
  //           () => {
  //             this.setState({
  //               showLoader: false,
  //             });
  //           }
  //         );
  //       } else {
  //         this.setState({
  //           showLoader: false,
  //         });
  //       }
  //     })
  //     .catch(
  //       this.setState({
  //         showLoader: false,
  //       })
  //     );
  // }

  render() {
    return (
      <div className="page__wrapper h-100">
        <Header {...this.props} />

        <div className="mainContainer CustomerTable">
          <Loader showLoader={this.state.showLoader} />

          <Row noGutters className="h-100">
            <Sidebar {...this.props} />
            <Col md={9} lg={10} className="boxTyp3">
              <div className="container-fluid">
                <div className="content-wrapper">
                  <Row noGutters className="mb-3">
                    <Col md={6} lg={8} className="">
                      <h3 className="font-weight-normal mb-0 line-ht color-33 fs-24">
                        Accounts{' '}
                        <span className="color-grey">
                          ({this.state.totalCount})
                        </span>
                      </h3>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    {/* <Col md={4} lg={4} className="">
                          <div className="cards-wrap">
                            <a href="" className="active card-img-wrapper"><img src={OrangeCard} className="img-fluid"/></a>
                            <a href="" className="card-img-wrapper"><img src={BlueCard} className="img-fluid"/></a>
                            <a href="" className="card-img-wrapper"><img src={PinkCard} className="img-fluid"/></a>
                            <a className="add-btn-btm">+</a>
                          </div>
                        </Col> */}
                    <Col md={12} lg={12} className="">
                      {/* <div className="row mb-4">
                                <div className="col-md-6">
                                    <div className="total-bal-wrap">
                                        <div className="total-header">
                                            <span className="color-grey fs22">Total Balance</span>
                                            <span className="text-greenish">$6,220.00</span>
                                            <span className="text-redish">$6,220.00</span>
                                        </div>
                                        
                                        <h2 className="font-55 text-center my-5">$7,610.00</h2>
                                        <div className="btm-btn-wrap text-center">
                                            <button className="deposit-button mr-4">Deposit</button>
                                            <button className="withdraw-btn">Withdraw</button>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="total-bal-wrap">
                                    <div className="swap-wrap"><span className="icon-SwapBtn"></span></div>
                                    <div className="graph-right-wrap">
                                      <span className="graph-head1">Income</span>
                                      <span className="graph-head2">Withdrawls</span>
                                    </div>
                                  </div>
                                </div>
                            </div> */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="history-wrapper">
                            <Row className="m-0">
                              <Col md={6} lg={6} className="">
                                <p className="mb-0 fs18 line-height">
                                  Transactional history
                                </p>
                              </Col>
                              {/* <Col md={6} lg={6} className="">
                                <FormControl
                                  type="text"
                                  placeholder="Search here"
                                  className="search-history"
                                />
                                <span className="icon-search"></span>
                              </Col> */}
                            </Row>
                            <Row className="mx-0 mt-4">
                              <Col md={12} lg={12} className="">
                                <div className="recent-wrap">
                                  <span className="color-grey">Recent</span>
                                  <a
                                    href=""
                                    className="float-right color-light-grey font-25"
                                  >
                                    {/* <span className="icon-calendar"></span> */}
                                  </a>
                                </div>
                              </Col>
                            </Row>

                            {this.state.transactionHistory.length !== 0 &&
                            !this.state.showLoader ? (
                              <div>
                                {this.state.transactionHistory &&
                                  this.state.transactionHistory.map(
                                    (item, index) => {
                                      return (
                                        <Row
                                          key={index}
                                          className="align-items-center"
                                        >
                                          <Col
                                            md={12}
                                            lg={12}
                                            className=" mb-4"
                                          >
                                            <div
                                              className={
                                                item.paymentTransactionType ===
                                                'debit'
                                                  ? 'recent-block border-left-red-thin'
                                                  : 'recent-block border-left-green-thin'
                                              }
                                            >
                                              <div className="date-wrap">
                                                <p className="mb-0 fs22">
                                                  {moment(
                                                    item.transactionDate
                                                  ).format('D')}
                                                </p>
                                                <span className="color-light-grey">
                                                  {moment(
                                                    item.transactionDate
                                                  ).format('MMM')}
                                                </span>
                                              </div>
                                              <div className="name-wrap">
                                                <h5>{item.name}</h5>
                                                <h5 className="color-grey font-weight-normal">
                                                  {item.transactionId}{' '}
                                                </h5>
                                              </div>

                                              <div className="float-right right-box">
                                                <div className="btn-wrapper1"></div>
                                                <span className="sm-text">
                                                  {item.paymentTransactionType ===
                                                  'debit' ? (
                                                    <Debit />
                                                  ) : (
                                                    <Credit />
                                                  )}{' '}
                                                  {item?.currency ===
                                                  CONSTANTS.CURRENCY.USD
                                                    ? '$'
                                                    : item?.currency ===
                                                      CONSTANTS.CURRENCY.NGN
                                                    ? '₦'
                                                    : '₦'}
                                                </span>
                                                {item.amount}
                                              </div>
                                            </div>
                                            <span className="recent-time-label">
                                              {moment(
                                                new Date(item.transactionDate),
                                                'YYYYMMDD'
                                              ).fromNow()}
                                            </span>
                                          </Col>
                                        </Row>
                                      )
                                    }
                                  )}
                              </div>
                            ) : (
                              <h5 className="text-center my-5">
                                {this.state.showLoader
                                  ? null
                                  : 'No Records Available'}
                              </h5>
                            )}
                            {this.state.transactionHistory?.length <=
                            0 ? null : (
                              <nav aria-label="Page navigation">
                                <ul className="pagination pg-blue deliva-pagination justify-content-end pr-180p">
                                  <li key={Math.random()} className="page-item">
                                    <button
                                      className="page-link rotate-180 control-btn"
                                      aria-label="Previous"
                                      onClick={() =>
                                        this.onChangePage(this.state.pageno - 1)
                                      }
                                      disabled={
                                        this.state.pageno === 1 ||
                                        this.state.totalPage === 0
                                      }
                                    >
                                      <span className="icon-next"></span>
                                      <span
                                        //className="prevNext"
                                        className={`sr-only ${
                                          this.state.pageno === 1 ||
                                          this.state.totalPage === 0
                                            ? ''
                                            : 'active'
                                        }`}
                                      >
                                        Previous
                                      </span>
                                    </button>
                                  </li>
                                  {this.state.totalPage > 0 &&
                                    this.state.pageArray.map((page, ind) => {
                                      return (
                                        <li key={ind} className="page-item">
                                          <a
                                            className={`page-link ${
                                              this.state.pageno === page
                                                ? 'active'
                                                : ''
                                            }`}
                                            onClick={() =>
                                              this.onChangePage(page)
                                            }
                                          >
                                            {page}
                                          </a>
                                        </li>
                                      )
                                    })}

                                  <li className="page-item">
                                    <button
                                      className="page-link control-btn"
                                      aria-label="Next"
                                      onClick={() =>
                                        this.onChangePage(this.state.pageno + 1)
                                      }
                                      disabled={
                                        this.state.pageno ===
                                          this.state.totalPage ||
                                        this.state.totalPage === 0
                                      }
                                    >
                                      <span className="icon-next"></span>
                                      <span
                                        className={`sr-only ${
                                          this.state.pageno ===
                                            this.state.totalPage ||
                                          this.state.totalPage === 0
                                            ? ''
                                            : 'active'
                                        }`}
                                      >
                                        Next
                                      </span>
                                    </button>
                                  </li>
                                  <li className="page-value page-val">
                                    Enter Per Page{' '}
                                    <input
                                      type="text"
                                      placeholder={
                                        CONSTANTS.SHOWRECORDS.NOOFRECORDS
                                      }
                                      name="records"
                                      value={this.state.records}
                                      onChange={(e) =>
                                        this.onChangeItemPerPage(e)
                                      }
                                    />
                                  </li>
                                </ul>
                              </nav>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <Footer {...this.props} />
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
export default Accounts
// export default withRouter(TotalOrderTable);

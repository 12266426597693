import React, { Component } from 'react'
import { Col, Image, Nav } from 'react-bootstrap'
import { Link, NavLink, Router } from 'react-router-dom'
import { clearLocalStorage } from '../../../common/helpers/Utils'
import tcPDF from '../../../assets/pdf/tc.pdf'
import CONSTANTS from '../../../common/helpers/Constants'
import { ReactComponent as OrderListIco } from '../../../assets/images/order-list-ico.svg'
import { ReactComponent as ReferralIcon } from '../../../assets/images/referral.svg'
// import "../../../assets/scss/icomoon.scss";

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  // componentDidMount() {
  //   console.log('sidebar', this.props);
  // }

  handleLogout() {
    clearLocalStorage()
    this.props.history.push('/login')
  }
  openPDF() {
    window.open(tcPDF, '_blank')
  }
  render() {
    let pathname = this.props.location.pathname
    return (
      <Col md={3} lg={2} className="sidebar">
        <div className="sidebarNav">
          <NavLink
            to="/dashboard"
            className={
              pathname.includes('/orders/') ||
              pathname.includes('/total/details/') ||
              pathname.includes('/intransit/details') ||
              pathname.includes('/delivered/details/') ||
              pathname.includes('/cancelled/details') ||
              pathname.includes('/dashboard')
                ? 'active-toggle'
                : ''
            }
          >
            <span className="icon-dashboard"></span> DASHBOARD
          </NavLink>

          <NavLink
            to="/processingagentlist"
            className={
              pathname.includes('/processingagentlist') ||
              pathname.includes('/storeprofile') ||
              pathname.includes('/totalprocessingorders')
                ? 'active-toggle'
                : ''
            }
          >
            <span className="icon-processing-centre"></span> PROCESSING CENTRES
          </NavLink>

          <NavLink
            to="/deliveryagentlist"
            className={
              pathname.includes('/deliveryagentlist') ||
              pathname.includes('/deliveryagentprofile') ||
              pathname.includes('/deliveryagentorders/totalorders/') ||
              pathname.includes('/deliveryagent/openbids') ||
              pathname.includes('/deliveryagent/totalbids/') ||
              pathname.includes('/deliveryagenttrips/totaltrips')
                ? 'active-toggle'
                : ''
            }
          >
            <span className="icon-delivery-agent"></span> DELIVERY AGENTS
          </NavLink>

          <NavLink
            to="/customerlist"
            className={
              pathname.includes('/customerlist') ||
              pathname.includes('/customerDetails')
                ? 'active-toggle'
                : ''
            }
          >
            <span className="icon-customers"></span> CUSTOMERS
          </NavLink>

          {/* <a id="showDatas"  className="active pointer"> */}
          <a
            id="showDatas"
            className={`${
              pathname.includes('/verification') ? 'active-toggle' : ''
            } active pointer`}
          >
            <span className="icon-verification dropdown-btn"></span>{' '}
            VERIFICATION & APPROVALS{' '}
            {/* <span>
                  <i className="fa fa-chevron-down" aria-hidden="true"></i>
                </span>  */}
          </a>
          <div className="dropdown-container">
            <NavLink
              to="/verification/3"
              className={
                pathname.includes('/verification/3') ||
                pathname.includes('/agent/verify')
                  ? 'active-toggle'
                  : ''
              }
            >
              <span className="icon-delivery-agent"></span> Delivery Agents
            </NavLink>
            <NavLink
              to="/verification/4"
              className={
                pathname.includes('/verification/4') ||
                pathname.includes('/processingagent/verify')
                  ? 'active-toggle'
                  : ''
              }
            >
              <span className="icon-ProcessingAgent"></span> PROCESSING AGENTS
            </NavLink>
          </div>

          {/* <NavLink
            to="/opendeliveryrequests"
            className={pathname.includes('/opendeliveryrequests') ? 'active-toggle' : ''}
          >
            <span className="icon-accouts"></span> Open Delivery Requests
          </NavLink> */}


          <NavLink
            to="/Accounts"
            className={pathname.includes('/Accounts') ? 'active-toggle' : ''}
          >
            <span className="icon-accouts"></span> Accounts
          </NavLink>
          {/* <NavLink to="/admin/PayoutSystem">
            <span className="icon-payoutSystemt"></span> Pay-out System
          </NavLink> */}
          {/* <NavLink to="/tnc" target="_blank">
            <span className="icon-verification"></span> Terms & Conditions
          </NavLink> */}
          <NavLink
            className=""
            to=""
            onClick={() => {
              window.open('https://delivahere.com/terms/', '_blank')
              // window.open('https://delivahere.com/terms/#privacy', '_blank')
              //window.open('/tnc', '_blank')
              
            }}
          >
            <span className="icon-verification"></span> Terms & Conditions
          </NavLink>
          {/* <NavLink
            className="noActives"
            to=""
            onClick={() => {
              this.openPDF()
            }}
          >
            <span className="icon-verification"></span> Terms & Conditions
          </NavLink> */}
          <NavLink
            to="/list"
            className={pathname.includes('/list') ? 'active-toggle' : ''}
          >
            <span className="icon-ManageAdmin1"></span> Manage Admin
          </NavLink>
          <NavLink
            to={`/billing/orderList/${CONSTANTS.ROLES.ADMIN}`}
            className={
              pathname.includes('/billing') ? 'active-toggle active' : ''
            }
          >
            <div className="icon-order-lists">
              <OrderListIco /> <span className="order-title">Order List</span>
            </div>
          </NavLink>
          <NavLink
            to="/referralList"
            className={
              pathname.includes('/referralList') ? 'active-toggle active' : ''
            }
          >
            <div className="icon-order-lists">
              <ReferralIcon />{' '}
              <span className="order-title">Referral List</span>
            </div>
          </NavLink>
         {/* <NavLink
            to="/notifications"
            className={
              pathname.includes('/notifications') ? 'active-toggle active' : ''
            }
          >
            <span className="icon-verification"></span> Notifications
          </NavLink> */}
          <NavLink
            className="noActives-new"
            to=""
            onClick={() => {
              this.handleLogout()
            }}
          >
            <span className="icon-logout"></span> Logout
          </NavLink>
          {/* <NavLink to="">
            <span className="icon-reports"></span> REPORTS / ANALYTICS
          </NavLink> */}
        </div>
      </Col>
    )
  }
}

export default Sidebar
